import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.primary.main,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1.75),
		width: '100%',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 1),
		},
	},
	listItem: {
		fontSize: 18,
		fontWeight: 500,
		fontStyle: 'normal',
		padding: 0,
		alignItems: 'flex-start',
	},
	box: {
		padding: theme.spacing(3, 0, 4, 0),
	},
	textBox: {
		fontSize: 12,
		fontWeight: 500,
		padding: theme.spacing(3, 2.5),
	},
	listContainer: {
		minHeight: theme.spacing(18),
	},
	container: {
		minHeight: theme.spacing(24),
	},
	listBox: {
		margin: theme.spacing(2.5, 0),
	},
	listIcon: {
		minWidth: 'auto !important',
		marginRight: 10,
		marginTop: 5,
	},
	listText: {
		fontSize: 14,
		color: theme.palette.primary.main,
		fontWeight: 500,
		margin: 0,
	},
	serviceButton: {
		background: '#BEE2E2 !important',
		borderRadius: '9px !important',
		color: `${theme.palette.primary.main} !important`,
		padding: '10px 34px !important',
		textTransform: 'none !important',
		width: 300,
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
	},
	serviceButtonAtBottom: {
		width: 'calc(100% - 16px)',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	disabled: {
		background: '#EEEEEE',
	},
	outline: {
		border: '1px solid #FFFFFF',
		color: '#FFFFFF',
		borderRadius: 9,
		padding: theme.spacing(1.25, 4.25),
		textTransform: 'none',
		width: 300,
		'&:hover': {
			border: '1px solid #FFFFFF',
			color: '#FFFFFF',
		},
		[theme.breakpoints.down('sm')]: {
			border: '1px solid rgba(30, 76, 78, 0.3)',
			color: theme.palette.primary.main,
		},
	},
	buttonBox: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	mt: {
		marginTop: theme.spacing(1.75),
	},
}));

export default useStyles;
