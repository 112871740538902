import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';
import React from 'react';

import HeartIcon from '../../../../assets/listIcon/HeartIcon.svg';
import PinIcon from '../../../../assets/listIcon/PinIcon.svg';
import useStyles from '../../Styles/SharedStyles.Style';

function AssualtExamMap() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={PinIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Quickly find a sexual assault exam near you"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={HeartIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Recommended first step and your most comprehensive option for post-care and support"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
			</List>
			<Divider />
			<Typography align="left" className={classes.textBox}>
				{'Exam locations offer Sexual Assault Evidence Kits with comprehensive DNA collection,' +
					' full-body evaluations, and access to advocacy and post-care services, like emergency contraception, STI testing, and PEP. '}
			</Typography>
		</Box>
	);
}

export default AssualtExamMap;
