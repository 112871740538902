import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
	},
	selectedItem: {
		// background: '#1E4C4E',
		background: '#FABBCB',
		color: '#003366',
		borderRadius: 59,
		padding: theme.spacing(1.75),
		// color: '#FFFFFF',
		width: 204,
		textAlign: 'center',
		fontSize: 18,
		lineHeight: '22px',
		cursor: 'pointer',
	},
	item: {
		padding: theme.spacing(1.75),
		width: 204,
		textAlign: 'center',
		cursor: 'pointer',
		fontSize: 18,
		lineHeight: '22px',
		color: '#003366',
	},
	navBar: {
		position: 'fixed',
		width: '100%',
		bottom: 0,
		borderTop: '2px solid #1E4C4E',
	},
	icon: {
		fontWeight: 300,
		fontSize: 12,
		color: theme.palette.primary.main,
		'& .Mui-selected': {
			fontWeight: 500,
		},
	},
}));

export default useStyles;
