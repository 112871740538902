import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import GettingStartedBg from '../../assets/BannerBg.svg';
import { useStore } from '../../store';
import { DEMO } from '../../utils/constants/demoData';
import { getOnClick } from '../../utils/disableServices';
import log from '../../utils/log';

import useStyles from './Styles/Home.Style';

function StartCard({ setOpenIntroVideo, userWelcomeContent }) {
	const classes = useStyles();
	// const { userWelcomeContent } = store;
	const handleClick = () => {
		setOpenIntroVideo(true);
	};
	const [store, dispatch] = useStore();
	const { serviceDisabled, isMobile } = store;
	log('userWelcomeContent', userWelcomeContent);

	return (
		<Box className={classes.startBox}>
			<Box className={classes.card}>
				<Card
					className={classes.startCard}
					style={{
						backgroundImage: !isMobile
							? `url(${userWelcomeContent?.bannerImg ?? GettingStartedBg})`
							: `url(${userWelcomeContent?.bannerImgMobile ?? GettingStartedBg})`,
					}}
					elevation={0}>
					<CardContent
						className={clsx({
							[classes.mobileViewCardContent]: isMobile,
							[classes.partnershipContentLeft]: userWelcomeContent?.textAlign === 'left',
							[classes.partnershipContentRight]: userWelcomeContent?.textAlign === 'right',
						})}>
						<Typography className={classes.cardHeading}>
							{userWelcomeContent?.title ?? 'Getting started'}
						</Typography>
						<Typography className={classes.cardContent}>
							{userWelcomeContent?.message ?? 'Hi 👋, not sure where to start?'}
						</Typography>
						<Button
							className={classes.startButton}
							onClick={getOnClick(handleClick, serviceDisabled, dispatch)}>
							{process.env.REACT_APP_ENV === DEMO
								? 'Explore our blog'
								: userWelcomeContent?.introVideoTitle ?? "Watch a video about Leda's services"}
						</Button>
						{/* <Typography>
              <a
                href={
                  userWelcomeContent?.blogLink ?? "https://www.leda.co/blog"
                }
                rel="noreferrer"
                style={{ color: "#1E4C4E" }}
                target="_blank"
              >
                {userWelcomeContent?.blogTitle ?? "Or explore the Leda blog"}
              </a>
            </Typography> */}
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
}

StartCard.propTypes = {
	setOpenIntroVideo: PropTypes.func,
	userWelcomeContent: PropTypes.shape({
		blogLink: PropTypes.string,
		blogTitle: PropTypes.string,
		introVideo: PropTypes.string,
		introVideoTitle: PropTypes.string,
		message: PropTypes.string,
		title: PropTypes.string,
		bannerImg: PropTypes.string,
		textAlign: PropTypes.string,
		bannerImgMobile: PropTypes.string,
	}),
};

export default StartCard;
