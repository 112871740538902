import { Dialog, DialogContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from '../Kit/Eligibility Quiz/Styles/AlertDialog.Style';

function KitAlertDialog({ open, setOpen }) {
	const classes = useStyles();

	return (
		<Dialog
			classes={{ root: classes.div, paper: classes.dialog }}
			onClose={() => setOpen(true)}
			open={open}>
			<DialogContent className={classes.dialogContent}>
				<Typography className={classes.content}>
					It looks like your organization is out of kits.
					<br />
					Please contact your admin
				</Typography>
			</DialogContent>
		</Dialog>
	);
}

KitAlertDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func,
};

export default KitAlertDialog;
