import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	footer: {
		marginTop: 'auto',
		width: '100%',
	},
	footerDiv: {
		minWidth: '100%',
		minHeight: '7vh',
		maxHeight: '7vh',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3),
			marginTop: theme.spacing(1),
			minHeight: '10vh',
			maxHeight: '10vh',
		},
		[theme.breakpoints.down('md')]: {
			bottom: 0,
			minWidth: '100%',
			minHeight: '7vh',
			maxHeight: '7vh',
			backgroundColor: theme.palette.primary.main,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		[theme.breakpoints.up('md')]: {
			bottom: 0,
			minWidth: '100%',
			minHeight: '7vh',
			maxHeight: '7vh',
			backgroundColor: theme.palette.primary.main,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	supportDiv: {
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		border: '2px solid white',
		maxWidth: 'fit-content',
		marginBottom: theme.spacing(3),
	},
	disclaimerDiv: {
		position: 'absolute',
		bottom: 0,
		padding: theme.spacing(5),
		color: theme.palette.default.main,
		backgroundColor: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			minHeight: '10vh',
			maxHeight: '10vh',
			flexDirection: 'column',
		},
	},
	icon: {
		fontSize: 8,
		color: theme.palette.default.main,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	footerText: {
		color: theme.palette.default.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	alertBox: {
		background: '#B3E2C2',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'center',
		padding: theme.spacing(5, 12.5),
	},
	alertText: {
		fontSize: 16,
		lineHeight: '25px',
		letterSpacing: '0.02em',
		color: '#000000',
	},
	disclaimerBox: {
		background: theme.palette.primary.main,
		padding: theme.spacing(5, 11.25),
		color: '#FFF',
		fontSize: 16,
		[theme.breakpoints.down('sm')]: {
			padding: 35,
			paddingBottom: 60,
		},
	},
	disclaimerText: {
		color: '#FFF',
		fontSize: 14,
	},
	disclaimerHeading: {
		fontWeight: 600,
		fontSize: 20,
		padding: theme.spacing(2.5, 0),
	},
	supportBox: {
		border: '1px solid #FFF',
		padding: theme.spacing(2.5, 4.25),
		width: 'fit-content',
	},
	supportText: {
		fontWeight: 600,
		fontSize: 16,
	},
	link: {
		color: '#FFFFFF',
	},
	footerTextLink: {
		color: theme.palette.default.main,
		cursor: 'pointer',
		textDecoration: 'underline',
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
			marginRight: theme.spacing(1),
		},
	},
	footerLinks: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
		},
	},
}));

export default styles;
