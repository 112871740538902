import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	link: {
		textDecoration: 'underline',
		color: 'inherit',
	},
}));

export default useStyles;
