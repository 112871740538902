import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	main: {
		padding: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0.75, 0.75, 0, 0.75),
		},
	},
	title: {
		fontSize: 16,
		lineHeight: '18px',
		fontWeight: 300,
		marginBottom: theme.spacing(1),
		color: theme.palette.primary.main,
	},
	kitNum: {
		fontSize: 20,
		lineHeight: '23px',
		fontWeight: 300,
		color: theme.palette.primary.main,
	},
	lowerBox: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	statusChip: {
		background: 'rgba(174, 228, 173, 0.34)',
		border: '1px solid #6AB550',
		color: '#569242',
		padding: theme.spacing(1, 3.25),
	},
	detailButton: {
		background: '#BEE2E2',
		borderRadius: 5,
		textTransform: 'none',
		fontSize: 14,
		fontWeight: 700,
		lineHeight: '16px',
		width: '100%',
		marginTop: theme.spacing(1.25),
		padding: theme.spacing(1.9, 0),
		color: theme.palette.primary.main,
		'&:hover': {
			background: '#BEE2E2',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(1.5),
			fontSize: 16,
			textAlign: 'center',
		},
	},
	dividerWrapper: {
		padding: theme.spacing(2.5, 0),
	},
	idBackground: {
		borderRadius: theme.spacing(1),
		backgroundColor: '#F5F5F5',
		padding: theme.spacing(2, 10, 1.75, 2),
	},
	contentDeliveryBox: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	boxTitle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	boxSubTitle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: theme.spacing(4),
	},
}));

export default useStyles;
