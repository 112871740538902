import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import ModalCloseIcon from '../../../../assets/ModalCloseIcon.svg';
import styles from '../../Styles/ToxicologyListModal.Style';

function ToxicologyListModal({ open, setOpen }) {
	const classes = styles();
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog classes={{ paper: classes.dialog }} onClose={handleClose} open={open} scroll="body">
			<DialogTitle className={classes.dialogTitle}>
				<Typography className={classes.title}>Drugs Included in Panel</Typography>
				<img
					aria-hidden
					alt="An icon to separate the different information about STI/STD testing timeline "
					className={classes.image}
					onClick={handleClose}
					src={ModalCloseIcon}
				/>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<div className={classes.content}>
					<Grid container>
						<Grid item xs={12} md={6} lg={6}>
							<ol>
								<li style={{ marginBottom: 8 }}>Phencyclidine or PCP</li>
								<li style={{ marginBottom: 8 }}>THC (marijuana)</li>
								<li style={{ marginBottom: 8 }}>Benzodiazepines - GHB, Rohypnol</li>
								<li style={{ marginBottom: 8 }}>Buprenorphine (Suboxone)</li>
								<li style={{ marginBottom: 8 }}>Cocaine - Benzoylecgonine</li>
								<li style={{ marginBottom: 8 }}>Amphetamines/Methamphetamine</li>
								<li style={{ marginBottom: 8 }}>Oxycodone - Oxymorphone</li>
							</ol>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<ol start={8}>
								<li style={{ marginBottom: 8 }}>Methadone</li>
								<li style={{ marginBottom: 8 }}>
									Opiates - Morphine, Hydrocodone, Hydromorphone, Heroin
								</li>
								<li style={{ marginBottom: 8 }}>
									Barbiturates - Amobarbital/Pentobarbital, Butalbital, Phenobarbital
								</li>
								<li style={{ marginBottom: 8 }}>Ecstasy - Ketamine</li>
								<li style={{ marginBottom: 8 }}>Propoxyphene Fentanyl Tramadol (Ultram)</li>
							</ol>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
		</Dialog>
	);
}

ToxicologyListModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
};

ToxicologyListModal.defaultProps = {
	open: false,
	setOpen: null,
};

export default ToxicologyListModal;
