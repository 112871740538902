import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import React from 'react';

import fileIcon from '../../../../assets/24x7LiveSupport/careTeam/fileIcon.svg';
import starIcon from '../../../../assets/24x7LiveSupport/careTeam/starIcon.svg';
import sthethoscopeIcon from '../../../../assets/24x7LiveSupport/careTeam/stethoscopeIcon.svg';
import useStyles from '../../Styles/SharedStyles.Style';

function CareTeam() {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img src={sthethoscopeIcon} alt="sthethoscopeIcon" />
					</ListItemIcon>
					<ListItemText
						primary="Team trained in sexual assault care"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img src={starIcon} alt="starIcon" />
					</ListItemIcon>
					<ListItemText
						primary="Support for Early Evidence Kits"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img src={fileIcon} alt="fileIcon" />
					</ListItemIcon>
					<ListItemText
						primary="Support documenting an assault"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<AccessTimeIcon style={{ fontSize: 18 }} />
					</ListItemIcon>
					<ListItemText
						primary="Response time: < 10 mins"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
			</List>
		</Box>
	);
}

export default CareTeam;
