import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	// dialog: {
	//   minWidth: "30%",
	//   borderRadius: 15,
	//   alignItems: "center",
	//   width: "80%",
	//   maxWidth: 1103,
	//   maxHeight: "90%",
	//   overflowY: "auto",
	//   [theme.breakpoints.down("md")]: {
	//     display: "initial"
	//   }
	// },
	dialog: {
		minWidth: '30%',
		borderRadius: 15,
		alignItems: 'center',
		width: '80%',
		maxWidth: 1103,
		maxHeight: '90%',
		overflowY: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	content: {
		fontSize: 20,
		lineHeight: '24px',
		color: theme.palette.primary.main,
		padding: theme.spacing(3, 4),
		margin: theme.spacing(0, 'auto'),
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			lineHeight: '20px',
			padding: theme.spacing(3, 0),
		},
	},
	actionBox: {
		width: '100%',
		justifyContent: 'center',
	},
	divider: {
		border: '1px dashed #000000',
		width: '80%',
		margin: 'auto',
	},
	dialogContent: {
		maxWidth: 805,
		margin: theme.spacing(0, 'auto'),
		padding: theme.spacing(4, 0, 0),
		fontSize: 16,
		lineHeight: '20px',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4, 3.75, 0),
		},
	},
	terms: {
		fontSize: 16,
		lineHeight: '20px',
		color: theme.palette.primary.main,
	},
	checkBox: {
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing(5, 0),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: theme.spacing(3, 0),
			alignItems: 'flex-start',
		},
	},
	checkIcon: {
		borderRadius: 5,
		padding: 0,
		color: '#B0BDBA',
		marginRight: theme.spacing(1.25),
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	list: {
		fontSize: 16,
		lineHeight: '20px',
		color: theme.palette.primary.main,
		listStyle: 'outside',
		margin: theme.spacing(0, 5),
		padding: 0,
		textAlign: 'initial',
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 2.5),
		},
	},
	listItem: {
		padding: theme.spacing(1.25, 0),
		display: 'list-item',
	},
	dialogActions: {
		flexDirection: 'column',
		paddingBottom: theme.spacing(5),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3),
			paddingBottom: theme.spacing(4),
		},
	},
	button: {
		backgroundColor: theme.palette.primary.second,
		color: theme.palette.primary.main,
		textTransform: 'none',
		borderRadius: 9,
		fontSize: 16,
		lineHeight: '20px',
		marginBottom: theme.spacing(5),
		padding: theme.spacing(2),
		width: theme.spacing(37.5),
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			lineHeight: '20px',
			padding: theme.spacing(2.5, 0),
			marginTop: 0,
			width: '100%',
			maxWidth: theme.spacing(37.5),
		},
	},
	dialogTitle: {
		width: '100%',
		textAlign: 'center',
		padding: theme.spacing(9.75, 0, 5),
		backgroundColor: theme.palette.primary.second,
	},
	title: {
		fontSize: 30,
		lineHeight: '37px',
		margin: theme.spacing(0, 'auto'),
		maxWidth: 720,
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			maxWidth: '90%',
			fontSize: 20,
			lineHeight: '24px',
		},
	},
	image: {
		position: 'absolute',
		cursor: 'pointer',
		top: theme.spacing(6.25),
		right: theme.spacing(6.25),
		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(5),
			right: theme.spacing(4),
			width: theme.spacing(1.5),
		},
	},
	caption: {
		fontSize: 13,
		lineHeight: '26px',
		color: theme.palette.primary.main,
		maxWidth: 805,
	},
	link: {
		color: theme.palette.secondary.main,
		textDecoration: 'none',
	},
}));

export default styles;
