import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import awsmobile from './aws-exports';
import worker from './mocks/browser';
import reportWebVitals from './reportWebVitals';
import './fonts/Gramatika Bold Slanted.otf';
import './fonts/Gramatika Bold.otf';
import './fonts/Gramatika Regular.otf';
import './fonts/Gramatika Slanted.otf';
import './fonts/GramatikaBold.woff';
import './fonts/GramatikaBoldSlanted.woff';
import './fonts/GramatikaRegular.woff';
import './fonts/GramatikaSlanted.woff';
import './fonts/Montserrat-Bold.ttf';
import './fonts/Montserrat-Black.ttf';
import './fonts/Montserrat-BlackItalic.ttf';
import './fonts/Montserrat-BoldItalic.ttf';
import './fonts/Montserrat-ExtraBold.ttf';
import './fonts/Montserrat-ExtraBoldItalic.ttf';
import './fonts/Montserrat-ExtraLight.ttf';
import './fonts/Montserrat-ExtraLightItalic.ttf';
import './fonts/Montserrat-Italic-VariableFont_wght.ttf';
import './fonts/Montserrat-Italic.ttf';
import './fonts/Montserrat-Light.ttf';
import './fonts/Montserrat-LightItalic.ttf';
import './fonts/Montserrat-Medium.ttf';
import './fonts/Montserrat-MediumItalic.ttf';
import './fonts/Montserrat-Regular.ttf';
import './fonts/Montserrat-SemiBold.ttf';
import { DEMO } from './utils/constants/demoData';

const initializeApp = async () => {
	Amplify.configure(awsmobile);

	const root = document.getElementById('root');

	// function to check on page load if user switched to other domain.
	window.onload = () => {
		const validEntries = ['navigate', 'reload'];
		const isReload = validEntries.includes(
			String(window.performance.getEntriesByType('navigation')[0].type)
		);
		sessionStorage.setItem('userPortalReload', isReload);
		if (!isReload) {
			sessionStorage.clear();
		}
	};

	if (process.env.REACT_APP_ENV === DEMO) {
		worker.start({
			onUnhandledRequest: 'bypass',
			quiet: true,
		});
	}

	if (root != null) {
		ReactDOM.render(
			<BrowserRouter>
				<App />
			</BrowserRouter>,
			root
		);
	}
};

initializeApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
