import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Checkbox,
	Divider,
	List,
	ListItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ModalCloseIcon from '../../assets/ModalCloseIcon.svg';
import { useStore } from '../../store';
import ROUTES from '../../utils/constants/routes';
import SERVICE_LIST from '../../utils/constants/serviceList';

import styles from './Styles/EligibilityPopup.Style';

function EligibilityPopup(props) {
	const classes = styles();
	const [store] = useStore();
	const history = useHistory();
	const { userServiceList } = store;
	const { setOpenEligibilityPopup, openEligibilityPopup, setOpenPlanBDialog } = props;
	const [termsChecked, setTermsChecked] = useState(false);

	const handleClick = () => {
		setOpenEligibilityPopup(false);
		const serviceData = userServiceList[SERVICE_LIST.PLAN_B];
		const pickup = serviceData?.pickup;
		const delivery = serviceData?.delivery;
		// log("inside plan B popup pickup", pickup);
		// log("inside plan B popup delivery", delivery);

		if (pickup && delivery) {
			setOpenPlanBDialog(true);
		} else if (pickup) {
			history.push(ROUTES.PICK_UP_PLAN_B);
		} else if (delivery) {
			history.push(ROUTES.ORDER_PLAN_B);
		}
	};

	return (
		<Dialog
			classes={{ root: classes.div, paper: classes.dialog }}
			onClose={() => setOpenEligibilityPopup(false)}
			open={openEligibilityPopup}
			scroll="body">
			<DialogTitle className={classes.dialogTitle}>
				<Typography className={classes.title}>
					Plan B One-Step is an emergency contraceptive that helps prevent pregnancy before it
					starts.
				</Typography>
				<img
					aria-hidden
					alt="An icon to separate the different information about STI/STD testing timeline "
					className={classes.image}
					onClick={() => setOpenEligibilityPopup(false)}
					src={ModalCloseIcon}
				/>
			</DialogTitle>
			<Divider className={classes.divider} />
			<DialogContent className={classes.dialogContent}>
				<Typography>
					Each Plan B tablet contains 1.5mg of Levonorgestral - an ingredient that&apos;s commonly
					at lower doses in birth control pills. Levonorgestral is a progestin hormone.
				</Typography>
				<br />
				<Typography variant="body1">
					If you follow the instructions to a T - by taking Plan B ithin 72 hours of a contraceptive
					accident or unprotected sex, it&apos;ll work by:
				</Typography>
				<br />
				<List className={classes.list}>
					<ListItem className={classes.listItem}>
						Temporarily stopping the release of an egg from the ovary (ovulation)
					</ListItem>
					<ListItem className={classes.listItem}>Preventing fertilization</ListItem>
					<ListItem className={classes.listItem}>
						Preventing a fertilized egg from attaching to the uterusby changing the uterine lining.
					</ListItem>
				</List>
				<Box className={classes.checkBox}>
					<Checkbox
						className={classes.checkIcon}
						color="primary"
						onClick={() => setTermsChecked(!termsChecked)}
						value={termsChecked}
					/>
					<label className={classes.terms}>
						{
							'I acknowledge that Plan B may not be effective for me if my body mass index is over 26 or I am over 155lbs. '
						}
					</label>
				</Box>
			</DialogContent>
			<DialogActions classes={{ root: classes.dialogActions }}>
				<Button
					className={classes.button}
					disabled={!termsChecked}
					fullWidth
					onClick={() => handleClick()}>
					Next
				</Button>
				<Typography className={classes.caption}>
					Possible side-effects include nausea, vomiting (
					<b>
						If you throw up within two hours of taking the pill, it won&apos;t work and you&apos;ll
						take it again
					</b>
					) headache, dizziness, upset stomach, or tender breasts for a short while after. Your next
					period may be a few days early or late. Your ability to get pregnant in the future will
					not be impacted. The effectiveness of your regular birth control method will not be
					impacted.
				</Typography>
			</DialogActions>
		</Dialog>
	);
}

EligibilityPopup.propTypes = {
	openEligibilityPopup: PropTypes.bool,
	setOpenEligibilityPopup: PropTypes.func,
	setOpenPlanBDialog: PropTypes.func,
};

export default EligibilityPopup;
