import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import fetchEEKOrderDetails from '../../../Services/fetchEEKOrderDetails';
import fetchKitStatusFromLab from '../../../Services/fetchKitStatus';
import { useStore } from '../../../store';
import getStatus from '../../../utils/constants/orderStatus';
import ROUTES from '../../../utils/constants/routes';
import SERVICE_LIST from '../../../utils/constants/serviceList';
import { getOnClick } from '../../../utils/disableServices';
import log from '../../../utils/log';

import useStyles from './Styles/EEK.Style';

function EEK() {
	let interval;
	const [store, dispatch] = useStore();
	const history = useHistory();
	const {
		user,
		kitResults: kitStatusFromLab,
		isMobile,
		kitActivated,
		kitOrderDetails,
		serviceDisabled,
		userServiceList,
	} = store;
	const classes = useStyles();
	const [kitStatus, setKitStatus] = useState();
	const [deliveryStatus, setDeliveryStatus] = useState(kitOrderDetails?.orderStatus ?? '');
	const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];
	log('kit result', kitStatusFromLab);

	const labStatus = kitStatus ? 'Check Lab Status' : 'Complete Kit';

	const status = useMemo(() => getStatus(deliveryStatus), [deliveryStatus]);

	const handleActivateKit = () => {
		history.push(ROUTES.ACTIVATE_KIT);
	};

	const handleLabButton = () => {
		if (labStatus === 'Complete Kit') {
			history.push(ROUTES.ACTIVATE_KIT);
		} else {
			history.push(ROUTES.EEK_TEST_RESULT);
		}
	};

	const handleViewDetails = () => {
		history.push(ROUTES.TRACK_KIT_ORDER);
	};

	const stopFetching = () => {
		if (interval) {
			clearInterval(interval);
			interval = null;
		}
	};

	const fetchData = async () => {
		if (status?.label === 'Delivered') {
			stopFetching();
			return;
		}
		try {
			if (status?.label !== 'Delivered') {
				if (user?.eekOrderId) {
					const data = await fetchEEKOrderDetails(user?.cognitoSub, user?.eekOrderId, dispatch);
					setDeliveryStatus(data?.orderStatus);
				}
			} else {
				stopFetching();
				if (kitStatusFromLab) setKitStatus(kitStatusFromLab);
				else if (user?.kitId)
					setKitStatus(fetchKitStatusFromLab(dispatch, user?.kitId, user?.cognitoSub));
			}
		} catch (err) {
			log(['Error fetching order details'], err);
		}
	};

	const startFetching = () => {
		if (!interval) {
			fetchData();

			interval = setInterval(() => {
				fetchData();
			}, 30000);
		}
	};

	useEffect(() => {
		log('user', user);
		startFetching();
		return () => stopFetching();
	}, [user]);

	return (
		<Box className={classes.main}>
			{!kitActivated && !user.kitActivatedOn ? (
				<Box>
					<Typography className={classes.title}>Kit Status</Typography>
					<Button
						className={classes.detailButton}
						disabled={user?.kitId || kitActivated}
						onClick={handleActivateKit}>
						Activate Kit
					</Button>
					{user.eekOrderId && (
						<>
							<Box className={classes.dividerWrapper}>
								<Divider />
							</Box>
							<Grid className={classes.lowerBox} container>
								<Grid item lg={12} md={12} xs={isMobile && 12}>
									<Typography className={classes.title}>Delivery Status</Typography>
									<Box className={classes.contentDeliveryBox} component="div">
										<Typography className={classes.kitNum} style={{ color: status?.color }}>
											{status?.label ? status?.label : <CircularProgress size={24} />}
										</Typography>
										<Button onClick={handleViewDetails} variant="outlined">
											View Details
										</Button>
									</Box>
								</Grid>
							</Grid>
						</>
					)}
				</Box>
			) : (
				<Box>
					<Box className={classes.boxTitle} component="div">
						<Typography>Your Kit ID</Typography>
						<Typography>Kit Status</Typography>
					</Box>
					<Box className={classes.boxSubTitle} component="div">
						<Box className={classes.idBackground}>
							<Typography>{user?.kitId}</Typography>
						</Box>
						<Typography style={{ color: status?.color }} variant="h6">
							{kitStatus === 'In Lab' ? kitStatus : 'Activated'}
						</Typography>
					</Box>
					<Box className={classes.dividerWrapper}>
						<Divider />
					</Box>
					<Button
						className={classes.detailButton}
						onClick={getOnClick(handleLabButton, serviceDisabled, dispatch, serviceData)}>
						{labStatus}
					</Button>
				</Box>
			)}
		</Box>
	);
}

export default EEK;
