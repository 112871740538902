import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CallIcon from '@material-ui/icons/Call';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import React from 'react';

import PhoneLink from '../../../../Components/PhoneLink';
import useStyles from '../../Styles/SharedStyles.Style';

function AssaultHotline() {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon style={{ minWidth: 20, marginRight: 8, marginTop: 5 }}>
						<CallIcon style={{ fontSize: 18 }} />
					</ListItemIcon>
					<ListItemText
						primary="Access support and local resources after assault with the help of a trained staff member"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon style={{ minWidth: 20, marginRight: 8, marginTop: 5 }}>
						<AccessTimeIcon style={{ fontSize: 18 }} />
					</ListItemIcon>
					<ListItemText
						primary="Available 24/7 via the National Sexual Assault Hotline"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon style={{ minWidth: 20, marginRight: 8, marginTop: 5 }}>
						<PhoneIphoneIcon style={{ fontSize: 18 }} />
					</ListItemIcon>
					<ListItemText
						primary={
							<>
								{'Call '}
								<PhoneLink contactNumber="18006564673" text="1-800-656-4673" />
								{' to be connected'}
							</>
						}
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
			</List>
		</Box>
	);
}

export default AssaultHotline;
