import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../../store';
import ROUTES from '../../../utils/constants/routes';
import SERVICE_LIST from '../../../utils/constants/serviceList';
import { getOnClick, getLabel } from '../../../utils/disableServices';
import useStyles from '../../Home/Styles/SharedStyles.Style';
import AlertDialog from '../Eligibility Quiz/AlertDialog';

function EEKButtonGroup() {
	const classes = useStyles();
	const history = useHistory();
	const [store, dispatch] = useStore();
	const { kitActivated, user, serviceDisabled, userServiceList } = store;
	const [openAlert, setOpenAlert] = useState(false);

	const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];

	const isKitAssignedOrOrdered = useMemo(
		() => user?.eekOrderId || user?.kitId || kitActivated,
		[user, kitActivated]
	);

	const handleEEKButton = () => {
		if (isKitAssignedOrOrdered) {
			setOpenAlert(true);
		} else {
			history.push(ROUTES.ELIGIBILITY_QUIZ);
		}
	};

	return (
		<>
			<Button
				className={clsx(classes.serviceButtonAtBottom, classes.serviceButton, classes.mt)}
				onClick={getOnClick(handleEEKButton, serviceDisabled, dispatch, serviceData)}>
				{getLabel('Get Started', serviceDisabled)}
			</Button>
			<AlertDialog open={openAlert} setOpen={setOpenAlert} />
		</>
	);
}

export default EEKButtonGroup;
