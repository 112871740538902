import { Box, Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import HeartIcon from '../../../../assets/listIcon/HeartIcon.svg';
import MoneyBagIcon from '../../../../assets/listIcon/MoneyBagIcon.svg';
import PinIcon from '../../../../assets/listIcon/PinIcon.svg';
import { useStore } from '../../../../store';
import { DEMO } from '../../../../utils/constants/demoData';
import ROUTES from '../../../../utils/constants/routes';
import SERVICE_LIST from '../../../../utils/constants/serviceList';
import { getOnClick } from '../../../../utils/disableServices';
import log from '../../../../utils/log';
import KitAlertDialog from '../../KitAlertDialog';
import useStyles from '../../Styles/SharedStyles.Style';

export function CheckEligibilityButton() {
	const classes = useStyles();
	const history = useHistory();
	const [store, dispatch] = useStore();
	const [isKitAvailable, setIsKitAvailable] = useState(true);
	const { kitActivated, userServiceList, user, serviceDisabled } = store;
	const serviceData = userServiceList[SERVICE_LIST.EARLY_EVIDENCE_KIT];
	log('user in eek', user);

	const isKitAssignedOrOrdered = useMemo(
		() => user?.eekOrderId || user?.kitId || kitActivated,
		[user, kitActivated]
	);

	const handleClick = () => {
		if (process.env.REACT_APP_ENV === DEMO) {
			history.push(ROUTES.ELIGIBILITY_QUIZ);
		} else if (isKitAssignedOrOrdered) {
			history.push(ROUTES.KIT_DASHBOARD);
		} else if (serviceData?.unitsUsed >= serviceData?.unitsAllowed) {
			setIsKitAvailable(false);
		} else {
			history.push(ROUTES.ELIGIBILITY_QUIZ);
		}
	};

	return (
		<>
			<Button
				className={classes.serviceButton}
				onClick={getOnClick(handleClick, serviceDisabled, dispatch, serviceData)}>
				{process.env.REACT_APP_ENV === DEMO || isKitAssignedOrOrdered ? 'Get Started' : 'Track Kit'}
			</Button>
			{!isKitAvailable && <KitAlertDialog open={!isKitAvailable} setOpen={setIsKitAvailable} />}
		</>
	);
}

// eslint-disable-next-line react/no-multi-comp
function EvidenceKit() {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={PinIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Collect time-sensitive DNA at-home after sexual assault"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={HeartIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Recommended first step if you are unwilling/ unable to complete a sexual assault exam. Does not replace in-person care"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={MoneyBagIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Kit includes mobile app with customized guidance, digital intake, and a 24/7 Care Team for live support with optional DNA testing with results in under 8 weeks"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				{/* <ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={ClipBoardIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Optional DNA testing with results in under 8 weeks"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem> */}
				{/* <ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={BoxIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Kits are located in Leda’s Resource Box"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem> */}
			</List>
		</Box>
	);
}

export default EvidenceKit;
