import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	dialog: {
		minWidth: '30%',
		borderRadius: 15,
		alignItems: 'center',
		width: '50%',
		maxWidth: 1100,
		maxHeight: '90%',
		overflowY: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	content: {
		fontSize: 16,
		fontWeight: 400,
		lineHeight: '98%',
		color: theme.palette.primary.main,
		padding: theme.spacing(3, 4),
		margin: theme.spacing(0, 'auto'),
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			lineHeight: '20px',
			padding: theme.spacing(3, 0),
		},
	},
	dialogContent: {
		maxWidth: 805,
		margin: theme.spacing(0, 'auto'),
		fontSize: 16,
		lineHeight: '20px',
		color: theme.palette.primary.main,
	},
	dialogTitle: {
		width: '100%',
		textAlign: 'center',
		padding: theme.spacing(5, 0, 5),
		backgroundColor: '#81B1FA',
	},
	title: {
		fontSize: 24,
		lineHeight: '24px',
		fontWeight: 400,
		margin: theme.spacing(0, 'auto'),
		maxWidth: 720,
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			maxWidth: '75%',
			fontSize: 20,
			lineHeight: '24px',
		},
	},
	image: {
		position: 'absolute',
		cursor: 'pointer',
		top: theme.spacing(6.25),
		right: theme.spacing(6.25),
		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(5.5),
			right: theme.spacing(4),
			width: theme.spacing(1.5),
		},
	},
}));

export default styles;
