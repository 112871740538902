import BloodIcon from '../../../assets/listIcon/BloodDrop.svg';
import BoxIcon from '../../../assets/listIcon/BoxIcon.svg';
import CheckIcon from '../../../assets/listIcon/CheckIcon.svg';
import DoctorCoatIcon from '../../../assets/listIcon/DoctorCoat.svg';
import HeartIcon from '../../../assets/listIcon/HeartIcon.svg';
import NotepadIcon from '../../../assets/listIcon/Notepad.svg';
import PillIcon from '../../../assets/listIcon/PillIcon.svg';

export const STI_LIST_LINKED = [
	{
		imgAlt: 'An icon to support information.',
		icon: CheckIcon,
		text: 'Test for STIs at-home with a kit made for and by survivors.',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: HeartIcon,
		text: 'Optional Early Evidence Kit Resource.',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: BoxIcon,
		text: 'Access STI/STD Testing in this user portal two weeks* after you activate your Early Evidence Kit.',
	},
];

export const STI_LIST_NON_LINKED = [
	{
		imgAlt: 'An icon to support information.',
		icon: BoxIcon,
		text: 'Tests for: Chlamydia, Gonorrhoea, Hep C, HIV (I & II), Herpes Simplex 2, Syphilis and Trichomoniasis',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: CheckIcon,
		text: 'Includes urine test for those born male or vaginal swab test for those born female and at-home blood drop collection kit',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: HeartIcon,
		text: 'Telehealth clinician visits offered for immediate help and long-term clinical care',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: PillIcon,
		text: 'Note: Some STIs can take up to 2 weeks to show in testing post exposure',
	},
];

export const DEMO_STI_LIST = [
	{
		imgAlt: 'An icon to support information.',
		icon: NotepadIcon,
		text: 'Tests for: Chlamydia, Gonorrhoea, Hep C, HIV (I & II), Herpes Simplex 2, Syphilis and Trichomoniasis',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: BloodIcon,
		text: 'Includes urine test for those born male or vaginal swab test for those born female and at-home blood drop collection kit',
	},
	// {
	//   imgAlt: 'An icon to support information.',
	//   icon: CheckIcon,
	//   text: 'Provides lab verified results of any potential exposures',
	// },
	{
		imgAlt: 'An icon to support information.',
		icon: DoctorCoatIcon,
		text: 'Post-results tele-health clinician visits offered for immediate help and long-term clinical care',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: PillIcon,
		text: 'Note: Some STIs can take up to 2 weeks to show in testing post exposure',
	},
];

export const STI_TEST = [
	{
		imgAlt: 'An icon to support information.',
		icon: NotepadIcon,
		text: 'Tests for: Chlamydia, Gonorrhea, Hepatitis C, HIV (I & II), Herpes Simplex 2, Syphilis and Trichomoniasis',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: BloodIcon,
		text: 'Includes urine test, swab test and at-home blood drop collection kit',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: CheckIcon,
		text: 'Provides lab verified results of any potential exposures',
	},
	{
		imgAlt: 'An icon to support information.',
		icon: DoctorCoatIcon,
		text: 'Post-results telehealth clinician visits included for immediate help and long-term clinical care',
	},
];
