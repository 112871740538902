import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';

import useStyles from './Styles/STITesting.Style';

function STITesting() {
	const classes = useStyles();

	return (
		<Box className={classes.main}>
			<List>
				<ListItem className={classes.item}>
					<ListItemIcon className={classes.icon}>
						<AddIcon />
					</ListItemIcon>
					<ListItemText className={classes.listText} disableTypography>
						Plan B and at-home STI/STD testing (optional)
					</ListItemText>
				</ListItem>
			</List>
		</Box>
	);
}

export default STITesting;
