import React from 'react';

import CustomCard from '../Components/CustomCard';

import SERVICE_LIST from './constants/serviceList';
import { servicesList } from './constants/servicesList';

export const resourceSelector = (localResource = [], generalResource = []) => {
	const availableResource = {
		'Local Resources': localResource,
		'General Resources': generalResource,
	};

	const resourceTypes = [
		'All Resources',
		...Object.keys(availableResource).filter((item) => availableResource[item]?.length > 0),
	];

	return [availableResource, resourceTypes];
};

export const serviceSelector = (services = {}) => {
	const availableServices = {
		'Immediate Care': [],
		'Follow-Up Care': [],
		'Long-Term Care': [],
		// '24/7 Live Support': [],
	};

	Object.keys(servicesList).forEach((item) => {
		servicesList[item].forEach((data) => {
			const list = services[data.heading]?.enabled ? [services[data.heading]] : [];
			if (list.length > 0) {
				availableServices[item] = [
					...availableServices[item],
					{ ...data, options: list[0].options, serviceDetails: list[0] },
				];
			}
			return availableServices;
		});
	});

	/* const availableLiveSupport = {};
	availableServices['24/7 Live Support'].forEach((s) => {
		availableLiveSupport[s.heading] = s;
	});
	availableServices['24/7 Live Support'].push(
		...servicesList['24/7 Live Support'].filter((service) => !availableLiveSupport[service.heading])
	); */

	availableServices['Follow-Up Care'].push(
		...servicesList['Follow-Up Care'].filter((service) => service.heading === SERVICE_LIST.CALLISTO)
	);

	// add bloom services to available services
	availableServices['Long-Term Care'].push(
		...servicesList['Long-Term Care'].filter((service) => service.heading === SERVICE_LIST.BLOOM)
	);

	// custom services
	Object.keys(services).forEach((service) => {
		if (services[service].isCustom && services[service].enabled) {
			const customService = {};
			customService.heading = services[service]?.name;
			customService.details = (
				<CustomCard description={services[service]?.description} logo={services[service]?.logo} />
			);
			customService.button = services[service]?.actionTitle;
			customService.action = services[service]?.link;
			customService.isCustom = services[service]?.isCustom;
			customService.headerColor = services[service]?.headerColor;
			customService.headerFontColor = services[service]?.headerFontColor;
			availableServices[services[service].type].push(customService);
		}
	});

	const userServices = [
		'All Services',
		...Object.keys(availableServices).filter((item) => availableServices[item].length > 0),
	];

	return [availableServices, userServices];
};
