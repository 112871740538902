import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import fetchToxicologyOrderDetails from '../../../Services/fetchToxicologyOrderDetails';
import { useStore } from '../../../store';
import getStatus from '../../../utils/constants/orderStatus';
import ROUTES from '../../../utils/constants/routes';
import SERVICE_LIST from '../../../utils/constants/serviceList';
import { getOnClick } from '../../../utils/disableServices';
import log from '../../../utils/log';

import useStyles from './Styles/EEK.Style';

function ToxicologyScreening() {
	let interval;
	const [store, dispatch] = useStore();
	const history = useHistory();
	const { user, isMobile, toxicologyOrderDetails, serviceDisabled, userServiceList } = store;
	const classes = useStyles();
	const [deliveryStatus, setDeliveryStatus] = useState(toxicologyOrderDetails?.orderStatus ?? '');
	const serviceData = userServiceList[SERVICE_LIST.TOXICOLOGY_SCREENING];
	const status = useMemo(() => getStatus(deliveryStatus), [deliveryStatus]);

	const handleActivateKit = () => {
		window.open('https://www.mylabbox.com/register/', '_blank');
	};

	const handleViewDetails = () => {
		history.push(ROUTES.TRACK_TOXICOLOGY_ORDER);
	};

	const stopFetching = () => {
		if (interval) {
			clearInterval(interval);
			interval = null;
		}
	};

	const fetchData = async () => {
		if (status?.label === 'Delivered') {
			stopFetching();
			return;
		}
		try {
			if (status?.label !== 'Delivered') {
				if (user?.toxicologyOrderId) {
					const data = await fetchToxicologyOrderDetails(
						user?.cognitoSub,
						user?.toxicologyOrderId,
						dispatch
					);
					setDeliveryStatus(data?.orderStatus);
				}
			} else {
				stopFetching();
			}
		} catch (err) {
			log(['Error fetching toxicology order details'], err);
		}
	};

	const startFetching = () => {
		if (!interval) {
			fetchData();

			interval = setInterval(() => {
				fetchData();
			}, 30000);
		}
	};

	useEffect(() => {
		log('user', user);
		startFetching();
		return () => stopFetching();
	}, [user]);

	return (
		<Box className={classes.main}>
			<Box>
				<Typography className={classes.title}>Kit Status</Typography>
				<Button
					className={classes.detailButton}
					disabled={status?.label !== 'DELIVERED'}
					onClick={getOnClick(handleActivateKit, serviceDisabled, dispatch, serviceData)}>
					Go To Test &nbsp;&nbsp;
					<OpenInNewRoundedIcon fontSize="small" />
				</Button>
				{user.toxicologyOrderId && (
					<>
						<Box className={classes.dividerWrapper}>
							<Divider />
						</Box>
						<Grid className={classes.lowerBox} container>
							<Grid item lg={12} md={12} xs={isMobile && 12}>
								<Typography className={classes.title}>Delivery Status</Typography>
								<Box className={classes.contentDeliveryBox} component="div">
									<Typography className={classes.kitNum} style={{ color: status?.color }}>
										{status?.label ? status?.label : <CircularProgress size={24} />}
									</Typography>
									<Button
										onClick={getOnClick(handleViewDetails, serviceDisabled, dispatch, serviceData)}
										variant="outlined">
										View Details
									</Button>
								</Box>
							</Grid>
						</Grid>
					</>
				)}
			</Box>
		</Box>
	);
}

export default ToxicologyScreening;
