import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../../store';
import ROUTES from '../../../utils/constants/routes';
import SERVICE_LIST from '../../../utils/constants/serviceList';
import { getOnClick, getLabel } from '../../../utils/disableServices';
import useStyles from '../../Home/Styles/SharedStyles.Style';
import AlertDialog from '../Eligibility Quiz/AlertDialog';

function STIButton() {
	const classes = useStyles();
	const history = useHistory();
	const [store, dispatch] = useStore();
	const { user, serviceDisabled, userServiceList } = store;
	const [openAlert, setOpenAlert] = useState(false);

	const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];

	const handleSTIButton = () => {
		if (user?.stiOrderId) {
			history.push(ROUTES.TRACK_STI_ORDER);
		} else {
			history.push(ROUTES.STI_REVIEW);
		}
	};

	return (
		<>
			<Button
				className={clsx(classes.serviceButtonAtBottom, classes.serviceButton, classes.mt)}
				onClick={getOnClick(handleSTIButton, serviceDisabled, dispatch, serviceData)}>
				{getLabel(user?.stiOrderId ? 'Track Order' : 'Get Started', serviceDisabled)}
			</Button>
			<AlertDialog open={openAlert} setOpen={setOpenAlert} />
		</>
	);
}

export default STIButton;
