import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CallIcon from '@material-ui/icons/Call';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import React from 'react';

import MessageLink from '../../../../Components/MessageLink';
import useStyles from '../../Styles/SharedStyles.Style';

function CrisisCounselors() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon style={{ minWidth: 20, marginRight: 8, marginTop: 5 }}>
						<CallIcon style={{ fontSize: 18 }} />
					</ListItemIcon>
					<ListItemText
						primary="Text with a compassionate crisis counselor anytime you need to"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon style={{ minWidth: 20, marginRight: 8, marginTop: 5 }}>
						<AccessTimeIcon style={{ fontSize: 18 }} />
					</ListItemIcon>
					<ListItemText
						primary="Available 24/7 in partnership with the Crisis Text Line"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon style={{ minWidth: 20, marginRight: 8, marginTop: 5 }}>
						<PhoneIphoneIcon style={{ fontSize: 18 }} />
					</ListItemIcon>
					<ListItemText
						primary={
							<div style={{ display: 'flex' }}>
								Text “Leda” to&nbsp;
								<MessageLink body="Leda" contactNumber="741741" text="741741" />
								&nbsp; to be connected.
							</div>
						}
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
			</List>
		</Box>
	);
}

export default CrisisCounselors;
