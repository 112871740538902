import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../store';
import { DEMO } from '../../utils/constants/demoData';
import ROUTES from '../../utils/constants/routes';
import SERVICE_LIST from '../../utils/constants/serviceList';
import { getOnClick } from '../../utils/disableServices';
import useStyles from '../Home/Styles/SharedStyles.Style';

function ToxicologyGetStarted() {
	const classes = useStyles();
	const [store, dispatch] = useStore();
	const history = useHistory();
	const { serviceDisabled, userServiceList, user } = store;
	const serviceData = userServiceList[SERVICE_LIST.TOXICOLOGY_SCREENING];

	const handleClick = () => {
		if (process.env.REACT_APP_ENV === DEMO) {
			history.push(ROUTES.TOXICOLOGY_REVIEW);
		} else if (user?.toxicologyOrderId) {
			history.push(ROUTES.TRACK_TOXICOLOGY_ORDER);
		} else {
			history.push(ROUTES.TOXICOLOGY_REVIEW);
		}
	};

	return (
		<Button
			className={classes.serviceButton}
			onClick={getOnClick(handleClick, serviceDisabled, dispatch, serviceData)}>
			{process.env.REACT_APP_ENV === DEMO || !user?.toxicologyOrderId
				? 'Get Started'
				: 'Track Order'}
		</Button>
	);
}

export default ToxicologyGetStarted;
