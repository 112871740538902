/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import React from 'react';

import { DEMO } from '../utils/constants/demoData';

import useStyles from './Styles/PhoneLink.Style';

function PhoneLink({ contactNumber, text }) {
	const classes = useStyles();
	return process.env.REACT_APP_ENV !== DEMO ? (
		<a className={classes.link} href={`tel:${contactNumber}`}>
			{text}
		</a>
	) : (
		<>{text}</>
	);
}

PhoneLink.propTypes = {
	contactNumber: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default PhoneLink;
