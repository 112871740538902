import React from 'react';

import AssaultHotline from '../../Pages/Home/Care Services/Crisis Support/AssaultHotline';
import CareTeam from '../../Pages/Home/Care Services/Crisis Support/CareTeam';
import CareTeamButton from '../../Pages/Home/Care Services/Crisis Support/CareTeamButton';
import CrisisCounselors from '../../Pages/Home/Care Services/Crisis Support/CrisisCounselors';
import Callisto from '../../Pages/Home/Care Services/Follow Up Care/Callisto';
import STITesting from '../../Pages/Home/Care Services/Follow Up Care/STITesting';
import CheckEligibilityButton from '../../Pages/Home/Care Services/Immediate Care/CheckEligibilityButton';
import ConfirmationButton from '../../Pages/Home/Care Services/Immediate Care/ConfirmationButton';
import EvidenceKit from '../../Pages/Home/Care Services/Immediate Care/EarlyEvidenceKit';
import Pep from '../../Pages/Home/Care Services/Immediate Care/PEP';
import PlanB from '../../Pages/Home/Care Services/Immediate Care/PlanB';
import AssualtExamMap from '../../Pages/Home/Care Services/Immediate Care/SexualAssualtExamMap';
import Toxicology from '../../Pages/Home/Care Services/Immediate Care/Toxicology';
import Bloom from '../../Pages/Home/Care Services/Long Term Care/Bloom';
import PrEP from '../../Pages/Home/Care Services/Long Term Care/PrEP';
import STIGetStarted from '../../Pages/STIKit/STIGetStarted';
import ToxicologyGetStarted from '../../Pages/Toxicology/ToxicologyGetStarted';

import SERVICE_LIST from './serviceList';

export const servicesList = {
	'Immediate Care': [
		{
			heading: SERVICE_LIST.SEXUAL_ASSAULT_EXAM_MAP,
			details: <AssualtExamMap />,
			button: 'View Location Map',
			action: 'https://www.leda.co/exam-locator',
		},
		{
			heading: SERVICE_LIST.EARLY_EVIDENCE_KIT,
			details: <EvidenceKit />,
			button: <CheckEligibilityButton />,
		},
		{
			heading: SERVICE_LIST.PEP,
			details: <Pep />,
			button: 'Get Started',
			action: 'https://www.leda.co/exam-locator',
		},
		{
			heading: SERVICE_LIST.PLAN_B,
			details: <PlanB />,
			button: <ConfirmationButton />,
		},
		{
			heading: SERVICE_LIST.TOXICOLOGY_SCREENING,
			details: <Toxicology />,
			button: <ToxicologyGetStarted />,
		},
	],
	'Follow-Up Care': [
		{
			heading: SERVICE_LIST.STI_TESTING,
			details: <STITesting />,
			button: <STIGetStarted />,
		},
		{
			heading: SERVICE_LIST.CALLISTO,
			details:<Callisto />,
			button: 'Get Started',
			action: 'https://www.callistovault.org/create-account'
		},
	],
	'Long-Term Care': [
		{
			heading: SERVICE_LIST.PREP,
			details: <PrEP />,
			button: 'Get Started',
			action: 'https://www.leda.co/exam-locator',
		},
		{
			heading: SERVICE_LIST.BLOOM,
			details: <Bloom />,
			button: 'Learn More',
			action: 'https://bloom.chayn.co/?utm_source=leda.co&utm_medium=referral&utm_id=ledahealth',
		},
	],
	'24/7 Live Support': [
		{ heading: SERVICE_LIST.SEXUAL_ASSAULT_HOTLINE, details: <AssaultHotline /> },
		{ heading: SERVICE_LIST.CRISIS_COUNSELORS, details: <CrisisCounselors /> },
		{ heading: SERVICE_LIST.CARE_TEAM, details: <CareTeam />, button: <CareTeamButton /> },
	],
};

export const serviceColors = {
	'Immediate Care': 'blue',
	'Follow-Up Care': 'orange',
	'Long-Term Care': 'blue',
	'24/7 Live Support': 'white',
	'Early Evidence Kit': 'blue',
	'Toxicology Screening': 'blue',
	'STI/STD Testing': 'yellow',
};

export const filterList = [
	'All Services',
	'Immediate Care',
	'Follow-Up Care',
	'Long-Term Care',
	'24/7 Live Support',
];
