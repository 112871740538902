import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		background: 'rgba(30, 76, 78, 0.04)',
		border: '1px solid #B0BDBA',
		boxSizing: 'border-box',
		borderRadius: 15,
		margin: theme.spacing(2.5, 0),
		color: theme.palette.primary.main,
	},
	accordionSummary: {
		'& .MuiAccordionSummary-content': {
			display: 'initial',
		},
	},
	expandIcon: {
		'& .MuiAccordionSummary-expandIcon': {
			position: 'relative',
			top: theme.spacing(-2.25),
		},
	},
	expandIconMobile: {
		'& .MuiAccordionSummary-expandIcon': {
			top: theme.spacing(-5),
		},
	},

	main: {
		padding: theme.spacing(2.15, 0, 0, 5.5),
		background: 'rgba(30, 76, 78, 0.04)',
		border: '1px solid #B0BDBA',
		boxSizing: 'border-box',
		borderRadius: 15,
		margin: theme.spacing(2.5, 0),
		color: theme.palette.primary.main,
		position: 'relative',
		'&:before': {
			height: 0,
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {
			margin: theme.spacing(1.5, 0),
		},
		'& .MuiAccordion-root.Mui-expanded': {
			margin: theme.spacing(2.5, 0),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2.15),
		},
	},
	icon: {
		position: 'absolute',
		right: theme.spacing(1.5),
		top: theme.spacing(1.75),
	},
	contentBox: {
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
			position: 'relative',
		},
		marginBottom: theme.spacing(2),
	},
	heading: {
		color: theme.palette.primary.main,
		fontSize: 25,
		[theme.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
	content: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			margin: theme.spacing(2.5, 0, 2.5, 0),
			fontWeight: 500,
		},
		fontSize: 16,
		margin: theme.spacing(2.5, 0, 0, 0),
		fontWeight: 500,
	},
	learnMoreButton: {
		boxSizing: 'border-box',
		borderRadius: theme.spacing(0, 0, 2, 2),
	},
	button: {
		color: '#003366',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2.5, 3.75),
			textTransform: 'none',
			fontSize: 16,
			display: 'flex',
			justifyContent: 'space-between',
		},
		padding: 0,
		marginTop: theme.spacing(2),
		textTransform: 'none',
		fontSize: 16,
		'&:hover': {
			background: '#F1F3F3',
		},
	},
	clockGrid: {
		padding: theme.spacing(2.5),
	},
	clock: {
		[theme.breakpoints.down('sm')]: {
			float: 'right',
		},
	},
	arrowIcon: {
		fontSize: 20,
	},
}));

export default useStyles;
