import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	main: {
		padding: theme.spacing(0, 3.25, 6, 2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 0.5, 6, 0),
		},
	},
	icon: {
		minWidth: 'unset',
		color: '#000000',
		marginRight: 10,
	},
	listText: {
		fontSize: 14,
		color: theme.palette.primary.main,
	},
	item: {
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
}));

export default useStyles;
