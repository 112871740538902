import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './Styles/STILinked.Style';

function STIList({ list, isLinked }) {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<List>
				{list.map((item) => (
					<ListItem className={classes.listItem} key={item?.text}>
						<ListItemIcon className={classes.listIcon}>
							<img alt={item?.text} src={item?.icon} />
						</ListItemIcon>
						<ListItemText
							primary={item?.text}
							primaryTypographyProps={{ className: classes.listText }}
						/>
					</ListItem>
				))}
			</List>
			{isLinked && (
				<>
					<Divider />
					<Typography className={classes.textBox}>
						*To account for the time it takes STIs to show up on test.
					</Typography>
				</>
			)}
		</Box>
	);
}

STIList.propTypes = {
	list: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
	isLinked: PropTypes.bool,
};

export default STIList;
