import ClockIcon from '../../assets/listIcon/ClockIcon.svg';
import LockIcon from '../../assets/Lock.svg';
import LockOpenedIcon from '../../assets/LockOpened.svg';

export const STI_TESTING_STATUS = {
	Pending: { label: 'Pending Kit Activation', icon: LockIcon },
	'In-Progress': { label: 'Kit will Activate in 2 weeks', icon: ClockIcon },
	Active: { label: 'Available', icon: LockOpenedIcon },
};

export const STI_ORDER_STATUS = {
	'order-fulfilled': 'Order Placed',
	'samples-received': 'Samples Received by Lab',
	'results-ready': 'View Results',
};
