/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import React from 'react';

import { useStore } from '../store';
import { getOnClick } from '../utils/disableServices';

import useStyles from './Styles/PhoneLink.Style';

function MessageLink({ contactNumber, body, text }) {
	const classes = useStyles();
	const [store, dispatch] = useStore();
	const { serviceDisabled } = store;

	return (
		<div
			aria-hidden
			className={classes.link}
			onClick={getOnClick(
				() => window.open(`sms:${contactNumber}&body=${body || 'Leda'}`),
				serviceDisabled,
				dispatch
			)}
			style={{ cursor: 'pointer' }}>
			{text}
		</div>
	);
}

MessageLink.propTypes = {
	contactNumber: PropTypes.string.isRequired,
	body: PropTypes.string,
	text: PropTypes.string.isRequired,
};

MessageLink.defaultProps = {
	body: '',
};

export default MessageLink;
