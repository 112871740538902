import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dialog: {
		minWidth: '30%',
		borderRadius: 15,
		alignItems: 'center',
		width: 891,
		maxWidth: 'unset',
		padding: theme.spacing(8.5),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(5),
		},
	},
	dialogContent: {
		textAlign: 'center',
		display: 'contents',
	},
	content: {
		fontSize: 20,
		lineHeight: '24px',
		color: theme.palette.primary.main,
		padding: theme.spacing(3, 4),
		margin: theme.spacing(0, 'auto'),
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			lineHeight: '20px',
			padding: theme.spacing(3, 0),
		},
	},
	actionBox: {
		width: '100%',
		justifyContent: 'center',
	},
	button: {
		margin: theme.spacing(3),
		backgroundColor: '#BEE2E2',
		color: theme.palette.primary.main,
		height: 'fit-content',
		textTransform: 'none',
		borderRadius: 9,
		fontSize: 16,
		lineHeight: '20px',
		padding: theme.spacing(1.25, 4.25),
		width: theme.spacing(37.5),
		maxWidth: theme.spacing(37.5),
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			lineHeight: '20px',
			padding: theme.spacing(2.5, 0),
			marginTop: 0,
			width: '100%',
			maxWidth: theme.spacing(37.5),
		},
	},
	link: {
		color: theme.palette.secondary.main,
		textDecoration: 'none',
	},
}));

export default useStyles;
