import { makeStyles } from '@material-ui/core';

import GettingStartedBg from '../../../assets/BannerBg.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		color: theme.palette.primary.main,
		background: '#FFFFFF',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	},
	body: {
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 4),
			background: '#FFFFFF',
		},
	},
	startBox: {
		background: '#FFFFFF',
	},
	card: {
		maxWidth: 1364,
		margin: '0px auto',
		padding: theme.spacing(7.5, 4),
		background: '#FFFFFF',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(5.5, 11.25),
			maxWidth: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	startCard: {
		borderRadius: 15,
		textAlign: 'center',
		backgroundImage: `url(${GettingStartedBg})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundColor: '#F2F0EA',
		background:
			'radial-gradient(117.26% 117.26% at 74.59% 8.02%, rgba(129, 177, 250, 0.42) 6.1%, rgba(234, 150, 240, 0.42) 56.68%, rgba(255, 255, 255, 0.42) 94.68%)',
	},
	content: {
		color: theme.palette.primary.main,
	},
	cardContent: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 14,
			lineHeight: '17px',
		},
		fontSize: 18,
		lineHeight: '22px',
	},
	cardHeading: {
		fontWeight: 600,
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(5),
			fontSize: 25,
		},
		marginTop: theme.spacing(6),
		fontSize: 40,
		color: '#003366',
	},
	cardBottomText: {
		fontSize: 16,
		fontWeight: 400,
		color: theme.palette.primary.main,
	},
	startButton: {
		textTransform: 'none',
		border: '1px solid #1E4C4E',
		boxSizing: 'border-box',
		borderRadius: 9,
		color: theme.palette.primary.main,
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 16,
		lineHeight: '20px',
		padding: theme.spacing(3.25, 6.25),
		margin: theme.spacing(4, 0),
		width: 'fit-content',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.5, 3),
			margin: theme.spacing(3.5),
			width: 'unset',
		},
	},
	serviceHeading: {
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 20,
		lineHeight: '24px',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(4),
		fontFamily: theme.typography.fontFamily,
	},
	box: {
		marginBottom: theme.spacing(3.75),
	},
	localResourcesCard: {
		border: '1px solid #BDC7C3',
		boxSizing: 'border-box',
		borderRadius: 15,
		marginBottom: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		height: 220,
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			margin: theme.spacing(0, 0, 2, 0.5),
		},
	},
	resourceContainer: {
		backgroundColor: '#FAFAFA',
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			backgroundColor: 'unset',
			paddingBottom: 20,
		},
	},
	resourceBox: {
		maxWidth: 1364,
		margin: '0 auto',
		padding: theme.spacing(0, 4),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 11.25),
			maxWidth: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			maxWidth: 'unset',
		},
	},
	resourceTitle: {
		fontWeight: 400,
		fontSize: 30,
		lineHeight: '37px',
		padding: theme.spacing(7.5, 5, 2.5, 3),
		color: theme.palette.primary.main,
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(4, 5, 2.5, 3),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(6.25, 8.75, 2.5, 3),
		},
	},
	resourceContent: {
		fontWeight: 400,
		fontSize: 18,
		lineHeight: '22px',
		color: theme.palette.primary.main,
		padding: theme.spacing(0, 2.5, 1.25, 1.25),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 2.5, 1.25, 1.25),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 4, 1.25, 1.25),
		},
	},
	loaderBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		width: '100%',
	},
	chatFab: {
		position: 'fixed',
		right: theme.spacing(2),
		bottom: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			position: 'fixed',
			right: theme.spacing(2),
			bottom: theme.spacing(3),
		},
	},
	errorDiv: {
		display: 'flex',
		margin: 'auto',
		flexDirection: 'column',
		height: '100vh',
		width: '100vw',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			margin: 'auto',
			flexDirection: 'column',
			height: '100vh',
			width: '80vw',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
		},
	},
	errorIcon: {
		fontSize: 48,
	},
	supportText: {
		color: '#F48273',
	},
	supportDiv: {
		overflowY: 'auto',
		width: 350,
		borderRadius: theme.spacing(1),
		border: '1px solid',
		backgroundColor: '#FFFFFF',
		position: 'fixed',
		right: 50,
		bottom: 100,
		[theme.breakpoints.down('sm')]: {
			minHeight: 'fit-content',
			maxHeight: 500,
			height: '70vh',
			width: 340,
			overflowY: 'auto',
			border: '1px solid',
			borderRadius: theme.spacing(1),
			backgroundColor: '#FFFFFF',
			position: 'fixed',
			right: theme.spacing(2),
			bottom: theme.spacing(15),
		},
	},
	partnershipContentLeft: {
		textAlign: 'left',
		marginLeft: theme.spacing(12.5),
		color: theme.palette.primary.main,
	},
	partnershipContentRight: {
		textAlign: 'right',
		marginRight: theme.spacing(12.5),
		color: theme.palette.primary.main,
	},
	mobileViewCardContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
	},
}));

export default useStyles;
