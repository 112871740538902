import { Button } from '@material-ui/core';
import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../../../store';
import { DEMO } from '../../../../utils/constants/demoData';
import ROUTES from '../../../../utils/constants/routes';
import SERVICE_LIST from '../../../../utils/constants/serviceList';
import { getOnClick } from '../../../../utils/disableServices';
import log from '../../../../utils/log';
import KitAlertDialog from '../../KitAlertDialog';
import useStyles from '../../Styles/SharedStyles.Style';

function CheckEligibilityButton() {
	const classes = useStyles();
	const history = useHistory();
	const [store, dispatch] = useStore();
	const [isKitAvailable, setIsKitAvailable] = useState(true);
	const { kitActivated, userServiceList, user, serviceDisabled } = store;
	const serviceData = userServiceList[SERVICE_LIST.EARLY_EVIDENCE_KIT];
	log('user in eek', user);

	const isKitAssignedOrOrdered = useMemo(
		() => user?.eekOrderId || user?.kitId || kitActivated,
		[user, kitActivated]
	);

	const handleClick = () => {
		if (process.env.REACT_APP_ENV === DEMO) {
			history.push(ROUTES.ELIGIBILITY_QUIZ);
		} else if (isKitAssignedOrOrdered) {
			history.push(ROUTES.KIT_DASHBOARD);
		} else if (serviceData?.unitsUsed >= serviceData?.unitsAllowed) {
			setIsKitAvailable(false);
		} else {
			history.push(ROUTES.ELIGIBILITY_QUIZ);
		}
	};

	return (
		<>
			<Button
				className={classes.serviceButton}
				onClick={getOnClick(handleClick, serviceDisabled, dispatch, serviceData)}>
				{process.env.REACT_APP_ENV === DEMO || !isKitAssignedOrOrdered
					? 'Get Started'
					: 'Track Kit'}
			</Button>
			{!isKitAvailable && <KitAlertDialog open={!isKitAvailable} setOpen={setIsKitAvailable} />}
		</>
	);
}

export default CheckEligibilityButton;
