import { Button } from '@material-ui/core';
import React from 'react';

import lockIcon from '../../../../assets/Lock.svg';
import { showIntercom } from '../../../../Services/intercom/method';
import { useStore } from '../../../../store';
import { DEMO } from '../../../../utils/constants/demoData';
import SERVICE_LIST from '../../../../utils/constants/serviceList';
import { getOnClick } from '../../../../utils/disableServices';
import useStyles from '../../Styles/SharedStyles.Style';

function CareTeamButton() {
	const classes = useStyles();
	const [store, dispatch] = useStore();
	const { serviceDisabled, userServiceList } = store;

	const serviceData = userServiceList[SERVICE_LIST.CARE_TEAM];

	const serviceDisableInDemo = process.env.REACT_APP_ENV === DEMO;

	const handleClick = async () => {
		await showIntercom();
	};
	return (
		<Button
			onClick={getOnClick(
				handleClick,
				serviceDisabled,
				dispatch,
				serviceData,
				serviceDisableInDemo
			)}
			className={classes.careTeamServiceButton}>
			Contact Care Team
			{serviceDisableInDemo && (
				<span className={classes.status}>
					<img
						alt="An icon to signify the current status of STI/STD Testing."
						style={{ verticalAlign: 'middle' }}
						src={lockIcon}
					/>
				</span>
			)}
		</Button>
	);
}

export default CareTeamButton;
