import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ROUTES from '../../../utils/constants/routes';

import useStyles from './Styles/AlertDialog.Style';

function AlertDialog({ open, setOpen }) {
	const classes = useStyles();
	const history = useHistory();

	const handleActivateKit = () => {
		history.push(ROUTES.ACTIVATE_KIT);
	};

	return (
		<Dialog
			classes={{ root: classes.div, paper: classes.dialog }}
			onClose={() => setOpen(false)}
			open={open}>
			<DialogContent className={classes.dialogContent}>
				<Typography className={classes.content}>
					{"It looks like there's already an Early Evidence Kit associated with your account. "}
					{'Please activate that kit to proceed further. Or contact us at '}
					<a
						className={classes.link}
						href="mailto:support@leda.co"
						rel="noreferrer"
						target="_blank">
						support@leda.co
					</a>
					{' to order another kit. '}
				</Typography>
			</DialogContent>
			<DialogActions className={classes.actionBox}>
				<Button className={classes.button} onClick={() => handleActivateKit()}>
					Activate Kit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

AlertDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func,
};

export default AlertDialog;
