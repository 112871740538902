import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../store';
import { DEMO } from '../../utils/constants/demoData';
import ROUTES from '../../utils/constants/routes';
import SERVICE_LIST from '../../utils/constants/serviceList';
import { getOnClick } from '../../utils/disableServices';
import useStyles from '../Home/Styles/SharedStyles.Style';

function STIGetStarted() {
	const classes = useStyles();
	const [store, dispatch] = useStore();
	const history = useHistory();
	const { serviceDisabled, userServiceList, user } = store;
	const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];

	const handleClick = () => {
		if (user?.stiOrderId) {
			history.push(ROUTES.TRACK_STI_ORDER);
		} else {
			history.push(ROUTES.STI_REVIEW);
		}
	};

	return (
		<Button
			className={classes.serviceButton}
			onClick={getOnClick(handleClick, serviceDisabled, dispatch, serviceData)}>
			{process.env.REACT_APP_ENV === DEMO || !user?.stiOrderId ? 'Get Started' : 'Track Order'}
		</Button>
	);
}

export default STIGetStarted;
