import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		margin: theme.spacing(1.25, 0),
		borderRadius: 15,
		border: '1px solid #BDC7C3',
		width: theme.spacing(52),
		height: theme.spacing(45.75),
		minHeight: 425,
		[theme.breakpoints.down('md')]: {
			width: theme.spacing(50),
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 212,
			height: 'unset',
			width: '100%',
			maxWidth: 344,
		},
	},
	rootWithButtons: {
		height: 'auto',
	},
	heading: {
		color: theme.palette.primary.main,
		fontSize: 20,
		height: theme.spacing(11),
		textAlign: 'left',
		padding: theme.spacing(4.5, 2.75, 3.75, 5),
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			lineHeight: '20px',
			padding: theme.spacing(3, 2.75),
		},
	},
	head: {
		height: theme.spacing(8.5),
	},
	title: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	blue: {
		background: '#D8E7FD',
	},
	orange: {
		background: 'rgba(249, 214, 126, 0.47)',
	},
	green: {
		background: '#DAF1E1',
	},
	red: {
		background: '#FFD7CE',
	},
	darkBlue: {
		background: '#81B1FA',
		height: 123,
		alignItems: 'end',
		[theme.breakpoints.down('sm')]: {
			height: 'fit-content',
		},
	},
	yellow: {
		background: '#FCECC3',
		height: 123,
		alignItems: 'end',
		[theme.breakpoints.down('sm')]: {
			height: 87,
			'& .MuiCardHeader-content': {
				display: 'flex',
				flexDirection: 'column',
			},
		},
	},
	lightBlue: {
		background: '#B6D3FE',
	},
	lightYellow: {
		background: '#F4DEA4',
	},
	status: {
		fontSize: 14,
		fontWeight: 500,
		float: 'right',
		marginTop: theme.spacing(-0.5),
	},
	serviceButton: {
		background: '#BEE2E2',
		borderRadius: 9,
		color: theme.palette.primary.main,
		padding: theme.spacing(1.25, 4.25),
		textTransform: 'none',
		width: theme.spacing(37.5),
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
	},
	serialNumBox: {
		borderRadius: '50%',
		position: 'absolute',
		top: 0,
		right: 0,
		width: 45,
		height: 45,
		textAlign: 'center',
		padding: theme.spacing(1.5),
		margin: theme.spacing(1.5),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		position: 'absolute',
		color: '#fff',
		width: theme.spacing(52),
		height: theme.spacing(45.75),
	},
	linkIconWrapper: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'white',
		width: '42px',
		alignItems: 'center',
		height: '42px',
		textAlign: 'center',
		borderRadius: '10px',
		opacity: 0.6,
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		minHeight: '335px',
		alignItems: 'center',
	},
	pepHeading: {
		backgroundColor: '#ffffff',
	},
	careTeamHeading: {
		backgroundColor: '#ffd7ce',
		filter: 'grayscale(100%)',
	},
	bloomHeading: {
		backgroundColor: '#ffffff',
	},
	callistoHeading: {
		backgroundColor: '#ffffff',
	},
	bloomButton: {
		backgroundColor: '#f04366',
		color: '#ffffff',
		'&:hover': {
			backgroundColor: '#d63c5b',
			color: '#ffffff',
		},
	},
	callistoButton: {
		backgroundColor: '#8bbcfc',
		'&:hover': {
			backgroundColor: '#7da9e3',
		},
	},
	alignNormal: {
		alignItems: 'normal',
	},
	customServiceHeader: {
		background: '#1A5936',
		color: '#fff',
		fontFamily: theme.typography.fontFamily,
	},
}));

export default useStyles;
