/* eslint-disable no-nested-ternary */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import userSignOut from '../Services/userSignOut';
import { useStore } from '../store/index';
import { DEMO } from '../utils/constants/demoData';
import ROUTES from '../utils/constants/routes';
import log from '../utils/log';

import styles from './Styles/Toolbar.Style';

function Toolbar(props) {
	const classes = styles();
	const [store, dispatch] = useStore();
	const history = useHistory();
	const { isMobile } = store;
	const [anchorEl, setAnchorEl] = useState(null);
	const { backButton, userProfileName } = props;

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSignOut = (e) => {
		e.preventDefault();
		userSignOut(history, dispatch);
	};

	const handleResetPassword = async (e) => {
		try {
			e.preventDefault();
			history.push(ROUTES.RESET_PASSWORD);
		} catch (error) {
			log('error going to resetting page: ', error);
		}
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (path) => {
		history.push(`/${path}`);
	};

	return (
		<div
			className={
				backButton ? classes.topBar : isMobile ? classes.topBarWithTitle : classes.topBar2
			}>
			{backButton ? (
				<Button classes={{ root: classes.backBtn }} onClick={() => handleClick('home')}>
					<KeyboardBackspaceIcon classes={{ root: classes.icon }} />
					<Typography variant="body2">Home</Typography>
				</Button>
			) : isMobile ? (
				<Typography>
					<b>Leda</b> Health
				</Typography>
			) : null}
			<Button
				classes={{ root: !isMobile ? classes.otherBtn : classes.otherBtn2 }}
				onClick={handleMenuClick}>
				<AccountCircleIcon classes={{ root: classes.icon }} variant="caption" />
				{!isMobile && (
					<Typography color="primary" variant="body2">
						{userProfileName && userProfileName !== null && userProfileName !== ''
							? userProfileName
							: ''}
					</Typography>
				)}
			</Button>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				elevation={0}
				getContentAnchorEl={null}
				keepMounted
				onClose={handleMenuClose}
				open={Boolean(anchorEl)}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				{process.env.REACT_APP_ENV !== DEMO && (
					<MenuItem classes={{ root: classes.logoutBtn }}>
						<a
							className={classes.faqButton}
							href="https://support.leda.co/hc/en-us/categories/4404959248155-User-Portal"
							rel="noreferrer"
							target="_blank">
							<HelpOutlineOutlinedIcon classes={{ root: classes.icon }} variant="caption" />
							<Typography variant="caption">FAQ</Typography>
						</a>
					</MenuItem>
				)}
				<MenuItem classes={{ root: classes.logoutBtn }} onClick={(e) => handleResetPassword(e)}>
					<VpnKeyOutlinedIcon classes={{ root: classes.icon }} variant="caption" />
					<Typography variant="caption">Reset Password</Typography>
				</MenuItem>
				<MenuItem classes={{ root: classes.logoutBtn }} onClick={(e) => handleSignOut(e)}>
					<ExitToAppIcon classes={{ root: classes.icon }} variant="caption" />
					<Typography variant="caption">Logout</Typography>
				</MenuItem>
			</Menu>
		</div>
	);
}

Toolbar.propTypes = {
	backButton: PropTypes.bool,
	userProfileName: PropTypes.string,
};

export default Toolbar;
