import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, lazy, Suspense } from 'react';
import { Switch, Route, withRouter, useLocation, Redirect } from 'react-router-dom';

import Loading from './Components/Loading';
import ServiceDisableDialog from './Components/ServiceDisableDialog';
import HomePage from './Pages/Home/index';
import SetNewPassword from './Pages/Public Pages/SetNewPassword';
import SignInWithEmail from './Pages/Public Pages/SignInWithEmail';
import UserConfirmation from './Pages/Public Pages/userConfirmation/UserConfirmation';
import OrderSTITest from './Pages/STIKit/OrderSTITest';
import PickupSTITest from './Pages/STIKit/PickupSTITest';
import STIReviewPage from './Pages/STIKit/STIReviewPage';
import TrackSTITest from './Pages/STIKit/TrackSTITest';
import fetchEEKOrderDetails from './Services/fetchEEKOrderDetails';
import fetchKitStatusFromLab from './Services/fetchKitStatus';
import fetchPlanBOrder from './Services/fetchPlanBOrderDetails';
import fetchToxicologyOrderDetails from './Services/fetchToxicologyOrderDetails';
import fetchUser from './Services/fetchUser';
import { useStore } from './store/index';
import ACTION_TYPE from './utils/constants/actionType';
import { DEMO } from './utils/constants/demoData';
import ROUTES from './utils/constants/routes';
import log from './utils/log';
import updateUserDetails from './utils/updateUserDetails';

const AlertDialog = lazy(() => import('./Components/AlertDialog'));
const CancelOrder = lazy(() => import('./Components/CancelOrder'));
const PrivateRoute = lazy(() => import('./Components/PrivateRoute'));
const Article1 = lazy(() => import('./Pages/Content/Article1'));
const Article2 = lazy(() => import('./Pages/Content/Article2'));
const Article3 = lazy(() => import('./Pages/Content/Article3'));
const Article4 = lazy(() => import('./Pages/Content/Article4'));
const Article5 = lazy(() => import('./Pages/Content/Article5'));
const Education = lazy(() => import('./Pages/Education'));
const ErrorPage = lazy(() => import('./Pages/ErrorPage'));

const KitActivation = lazy(() => import('./Pages/Kit'));
const EEKTestResults = lazy(() => import('./Pages/Kit/EEKTestResults'));
const EligibilityQuiz = lazy(() => import('./Pages/Kit/Eligibility Quiz'));
const KitDashboard = lazy(() => import('./Pages/Kit/KitDashboard'));
const OrderKit = lazy(() => import('./Pages/Kit/OrderEEK'));
const PickupKit = lazy(() => import('./Pages/Kit/PickupKit'));
const TrackEEK = lazy(() => import('./Pages/Kit/TrackEEK'));
const LearnMore = lazy(() => import('./Pages/LearnMore'));
const OrderPlanB = lazy(() => import('./Pages/Plan B/OrderPlanB'));
const PickupPlanB = lazy(() => import('./Pages/Plan B/PickupPlanB'));
const TrackPlanB = lazy(() => import('./Pages/Plan B/TrackPlanB'));
const EnterCode = lazy(() => import('./Pages/Public Pages/EnterCode'));
const EnterEmail = lazy(() => import('./Pages/Public Pages/EnterEmail'));
const ForgotPassword = lazy(() => import('./Pages/Public Pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./Pages/Public Pages/ResetPassword'));
const SetupAccount = lazy(() => import('./Pages/Public Pages/SetupAccount'));
const Resources = lazy(() => import('./Pages/Resources'));
const OrderToxicology = lazy(() => import('./Pages/Toxicology/OrderToxicology'));
const PickupToxicology = lazy(() => import('./Pages/Toxicology/PickupToxicology'));
const ToxicologyReviewPage = lazy(() => import('./Pages/Toxicology/ToxicologyReviewPage'));
const TrackToxicology = lazy(() => import('./Pages/Toxicology/TrackToxicology'));
const WitnessForm = lazy(() => import('./Pages/Witness Form/index'));

const drawerWidth = 600;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: 'red',
		padding: theme.spacing(3),
	},
}));

function MainApp() {
	log('inside mainApp');
	const loc = useLocation();
	const [, dispatch] = useStore();
	const isLoggedIn = sessionStorage.getItem('userLoggedIn') === 'true';

	const classes = useStyles();
	log('loc inside mainapp', loc);

	const checkWindowWidth = () => {
		const ismobile = window.innerWidth < 1000;
		dispatch({
			type: ACTION_TYPE.SET_IS_MOBILE_VIEW,
			isMobile: ismobile,
		});
	};

	const fetchUserData = async () => {
		const userBody = await fetchUser();
		if (userBody) {
			updateUserDetails(userBody, dispatch);

			if (userBody?.eekOrderId) {
				fetchEEKOrderDetails(userBody?.cognitoSub, userBody?.eekOrderId, dispatch);
			}
			if (userBody?.planBOrderId) {
				fetchPlanBOrder(userBody?.cognitoSub, userBody?.planBOrderId, dispatch);
			}
			if (userBody?.kitId) {
				fetchKitStatusFromLab(dispatch, userBody?.kitId, userBody?.cognitoSub);
			}
			if (userBody?.toxicologyOrderId) {
				fetchToxicologyOrderDetails(userBody?.cognitoSub, userBody?.toxicologyOrderId, dispatch);
			}
		}
	};

	useEffect(() => {
		log({ loc });
		window.scrollTo(0, 0);
	}, [loc]);

	useEffect(() => {
		if (isLoggedIn) {
			fetchUserData();
		}
	}, [isLoggedIn]);

	useEffect(() => {
		checkWindowWidth();
		window.addEventListener('resize', checkWindowWidth, false);
	}, []);

	return (
		<Suspense fallback={<Loading />}>
			<div className={classes.root}>
				<AlertDialog />
				<Switch>
					{process.env.REACT_APP_ENV !== DEMO && (
						<Route component={SetupAccount} path={ROUTES.SETUP_ACCOUNT} />
					)}
					<Route component={EnterCode} path={ROUTES.ENTER_VERIFICATION_CODE} />
					{process.env.REACT_APP_ENV !== DEMO && (
						<Route component={ForgotPassword} path={ROUTES.FORGOT_PASSWORD} />
					)}
					<Route component={SignInWithEmail} path={ROUTES.SIGN_IN} />
					<Route component={SignInWithEmail} exact path={ROUTES.DEFAULT} />
					<Route component={UserConfirmation} exact path={ROUTES.USER_CONFIRM} />
					<PrivateRoute component={SetNewPassword} path={ROUTES.SET_NEW_PASSWORD} />
					<PrivateRoute component={EnterEmail} path={ROUTES.ENTER_EMAIL} />
					<PrivateRoute component={ResetPassword} path={ROUTES.RESET_PASSWORD} />
					<PrivateRoute component={HomePage} path={ROUTES.HOME} />
					<PrivateRoute component={LearnMore} path={ROUTES.LEARN_MORE} />
					<PrivateRoute component={PickupKit} path={ROUTES.PICK_UP_KIT} />
					<PrivateRoute component={Resources} path={ROUTES.RESOURCE_CENTER} />
					<PrivateRoute component={CancelOrder} path={ROUTES.CANCEL_ORDER} />
					<PrivateRoute component={OrderKit} path={ROUTES.ORDER_KIT} />
					<PrivateRoute component={TrackEEK} path={ROUTES.TRACK_KIT_ORDER} />
					<PrivateRoute component={EEKTestResults} path={ROUTES.EEK_TEST_RESULT} />
					<PrivateRoute component={KitActivation} path={ROUTES.ACTIVATE_KIT} />
					<PrivateRoute component={EligibilityQuiz} path={ROUTES.ELIGIBILITY_QUIZ} />
					<PrivateRoute component={KitDashboard} path={ROUTES.KIT_DASHBOARD} />
					<PrivateRoute component={STIReviewPage} path={ROUTES.STI_REVIEW} />
					<PrivateRoute component={OrderSTITest} path={ROUTES.ORDER_TEST} />
					<PrivateRoute component={PickupSTITest} path={ROUTES.PICK_UP_STI} />
					<PrivateRoute component={TrackSTITest} path={ROUTES.TRACK_STI_ORDER} />
					<PrivateRoute component={Article1} path={ROUTES.ARTICLE_1} />
					<PrivateRoute component={Article2} path={ROUTES.ARTICLE_2} />
					<PrivateRoute component={Article3} path={ROUTES.ARTICLE_3} />
					<PrivateRoute component={Article4} path={ROUTES.ARTICLE_4} />
					<PrivateRoute component={Article5} path={ROUTES.ARTICLE_5} />
					<PrivateRoute component={OrderPlanB} path={ROUTES.ORDER_PLAN_B} />
					<PrivateRoute component={PickupPlanB} path={ROUTES.PICK_UP_PLAN_B} />
					<PrivateRoute component={TrackPlanB} path={ROUTES.TRACK_PLAN_B_ORDER} />
					<PrivateRoute component={Education} path={ROUTES.EDUCATION} />
					<PrivateRoute component={CancelOrder} path={ROUTES.CANCEL_PLAN_B_ORDER} />
					<PrivateRoute component={ToxicologyReviewPage} path={ROUTES.TOXICOLOGY_REVIEW} />
					<PrivateRoute component={OrderToxicology} path={ROUTES.ORDER_TOXICOLOGY} />
					<PrivateRoute component={PickupToxicology} path={ROUTES.PICK_UP_TOXICOLOGY} />
					<PrivateRoute component={TrackToxicology} path={ROUTES.TRACK_TOXICOLOGY_ORDER} />
					<Route component={ErrorPage} path={ROUTES.ERROR} />
					<Route component={WitnessForm} path={ROUTES.WITNESS_FORM} />
					<Redirect to={ROUTES.ERROR} />
				</Switch>
				<ServiceDisableDialog />
			</div>
		</Suspense>
	);
}

export default withRouter(MainApp);
