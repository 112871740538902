import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import KitDialog from '../../../../Components/KitDialog';
import { useStore } from '../../../../store';
import ROUTES from '../../../../utils/constants/routes';
import SERVICE_LIST from '../../../../utils/constants/serviceList';
import { getOnClick } from '../../../../utils/disableServices';
import log from '../../../../utils/log';
import EligibilityPopup from '../../../Plan B/EligibilityPopup';
import useStyles from '../../Styles/SharedStyles.Style';

function ConfirmationButton() {
	const classes = useStyles();
	const [store, dispatch] = useStore();
	const history = useHistory();
	const { planBOrderDetails, serviceDisabled, userServiceList } = store;
	const [openEligibilityPopup, setOpenEligibilityPopup] = useState(false);
	const [openPlanBDialog, setOpenPlanBDialog] = useState(false);
	const serviceData = userServiceList[SERVICE_LIST.PLAN_B];

	log('planBOrderDetails', planBOrderDetails);

	const handleClick = () => {
		if (
			planBOrderDetails &&
			planBOrderDetails !== null &&
			planBOrderDetails?.orderStatus !== 'DELIVERED'
		) {
			history.push(ROUTES.TRACK_PLAN_B_ORDER);
		} else {
			setOpenEligibilityPopup(true);
		}
	};

	return (
		<>
			<Button
				className={classes.serviceButton}
				onClick={getOnClick(handleClick, serviceDisabled, dispatch, serviceData)}>
				{planBOrderDetails &&
				planBOrderDetails !== null &&
				planBOrderDetails?.orderStatus !== 'DELIVERED'
					? 'Track Plan B Order'
					: 'Get Started'}
			</Button>
			<EligibilityPopup
				openEligibilityPopup={openEligibilityPopup}
				setOpenEligibilityPopup={setOpenEligibilityPopup}
				setOpenPlanBDialog={setOpenPlanBDialog}
			/>
			<KitDialog
				open={openPlanBDialog}
				service={SERVICE_LIST.PLAN_B}
				setOpen={setOpenPlanBDialog}
				title="How would you like to get your Plan B?"
			/>
		</>
	);
}

export default ConfirmationButton;
