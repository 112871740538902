import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		margin: theme.spacing(1.25, 0),
		borderRadius: 9,
		border: '1px solid #C7D2D3',
		'&:before': {
			height: 0,
		},
		'&:last-child': {
			borderRadius: 9,
		},
	},
	heading: {
		color: theme.palette.primary.main,
		fontSize: 16,
	},
	head: {
		height: theme.spacing(8.5),
	},
	title: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	details: {
		background: '#FFF',
		flexDirection: 'column',
		borderTop: '1px solid #C7D2D3',
		padding: theme.spacing(1, 2),
	},
	blue: {
		background: '#D8E7FD',
	},
	orange: {
		background: 'rgba(249, 214, 126, 0.47)',
	},
	green: {
		background: '#DAF1E1',
	},
	red: {
		background: '#FFD7CE',
	},
	serviceButton: {
		background: '#BEE2E2',
		borderRadius: 9,
		color: theme.palette.primary.main,
		padding: theme.spacing(1.25, 4.25),
		textTransform: 'none',
		width: theme.spacing(37.5),
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
	},
	action: {
		background: '#FFFFFF',
		justifyContent: 'center',
		paddingBottom: theme.spacing(2),
		borderRadius: theme.spacing(0, 0, 1, 1),
	},
	status: {
		fontSize: 14,
		fontWeight: 500,
		float: 'right',
		marginTop: 5,
	},
	bloomHeading: {
		backgroundColor: '#ffffff',
	},
}));

export default useStyles;
