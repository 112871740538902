import { Box, Button, Checkbox, Menu, MenuItem } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import styles from './Styles/ServicesFilter.Style';

function ServicesFilter({ setSelectedService, selectedService, filterList }) {
	const classes = styles();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<Button
				className={classes.filterButton}
				endIcon={<FilterListIcon />}
				fullWidth
				onClick={(e) => setAnchorEl(e.currentTarget)}>
				{selectedService}
			</Button>
			<Menu
				PaperProps={{ className: classes.menu }}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				className={classes.filterMenu}
				getContentAnchorEl={null}
				keepMounted
				onClose={handleMenuClose}
				open={Boolean(anchorEl)}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				value={selectedService}>
				{filterList.map((item, idx) => (
					<MenuItem
						onClick={() => {
							setSelectedService(filterList[idx]);
							setAnchorEl(null);
						}}
						value={item}>
						<Checkbox
							checked={selectedService === item}
							checkedIcon={<RadioButtonCheckedRoundedIcon />}
							className={selectedService === item ? classes.customChecked : classes.customUnchecked}
							icon={<RadioButtonUncheckedIcon />}
						/>
						{item}
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
}

ServicesFilter.propTypes = {
	selectedService: PropTypes.string,
	setSelectedService: PropTypes.func,
	filterList: PropTypes.arrayOf(PropTypes.string),
};

export default ServicesFilter;
