import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';

import LockIcon from '../../../../assets/Lock.svg';
import useStyles from '../../Styles/SharedStyles.Style';

function Callisto() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={LockIcon} />
					</ListItemIcon>
					<ListItemText
						primary={
							<>
								Connecting Survivors with Callisto Vault
								<ul type="circle">
									<li>
										Learn if someone else was harmed by the same perpetrator using our encrypted and
										secure 3-step Matching System
									</li>
									<li>
										Create a timestamped record of your incident using out self-paced documentation
										tool, the Incident Log
									</li>
								</ul>
							</>
						}
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
			</List>
		</Box>
	);
}

export default Callisto;
