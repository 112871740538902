import { AppBar, Box, Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './Styles/TabBar.Style';

function TabBar({ filterList, value, setValue }) {
	const classes = useStyles();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box className={classes.appBox}>
			<AppBar className={classes.bar} color="inherit" elevation={0} position="static">
				<Tabs
					TabIndicatorProps={{ className: classes.tabIndicator }}
					indicatorColor="primary"
					onChange={handleChange}
					textColor="primary"
					value={value}
					variant="standard">
					{filterList.map((item) => (
						<Tab className={classes.tab} key={item} label={item} />
					))}
				</Tabs>
			</AppBar>
		</Box>
	);
}

TabBar.propTypes = {
	filterList: PropTypes.arrayOf(PropTypes.string),
	value: PropTypes.number,
	setValue: PropTypes.func,
};

TabBar.defaultProps = {
	filterList: [],
	value: 0,
	setValue: null,
};

export default TabBar;
