import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import React, { useState } from 'react';

import LinkIcon from '../../../../assets/listIcon/Link.svg';
import MicroscopeIcon from '../../../../assets/listIcon/Microscope.svg';
import PillIcon from '../../../../assets/listIcon/PillIcon.svg';
import useStyles from '../../Styles/SharedStyles.Style';

import ToxicologyListModal from './ToxicologyListModal';

function Toxicology() {
	const classes = useStyles();
	const [openDialog, setOpenDialog] = useState(false);
	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<AccessTimeIcon style={{ fontSize: 18 }} />
					</ListItemIcon>
					<ListItemText
						primary="Recommended use within 6-24 hours of incident"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={MicroscopeIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Provides lab verified results of any drugs in your system for documentation purposes
"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={PillIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Indicates levels of drugs in system to indicate level of intoxication"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem} button onClick={() => setOpenDialog(true)}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={LinkIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Click here to see list of drugs included"
						primaryTypographyProps={{ className: classes.listTextLink }}
					/>
				</ListItem>
			</List>
			<ToxicologyListModal open={openDialog} setOpen={setOpenDialog} />
		</Box>
	);
}

export default Toxicology;
