import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	customChecked: {
		color: '#1E4C4E !important',
		'&:checked': {
			color: '#1E4C4E !important',
		},
	},
	customUnchecked: {
		color: '#B0BDBA !important',
	},
	filterButton: {
		border: '1px solid #B0BDBA',
		textTransform: 'none',
		margin: theme.spacing(1.25, 0),
		background: '#FFFFFF',
		boxSizing: 'border-box',
		borderRadius: 9,
		justifyContent: 'space-between',
		padding: theme.spacing(1.25, 2.5),
	},
	filterIcon: {
		background: 'rgba(176, 189, 186, 0.38)',
		color: theme.palette.primary.main,
		borderRadius: 11,
		padding: theme.spacing(0.5),
	},
	filterMenu: {
		'& .MuiMenu-paper': {
			border: '1px solid #B0BDBA',
			boxSizing: 'border-box',
			borderRadius: theme.spacing(1.5),
			width: '84%',
			// background: 'red'
		},
	},
	menu: {
		width: '83%',
		marginLeft: theme.spacing(2.5),
		borderRadius: theme.spacing(1.5),
	},
}));

export default styles;
