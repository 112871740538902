import React from 'react';

import STITesting from '../../Pages/Home/Care Services/Follow Up Care/STITesting';
import EvidenceKit from '../../Pages/Home/Care Services/Immediate Care/EarlyEvidenceKit';
import Toxicology from '../../Pages/Home/Care Services/Immediate Care/Toxicology';
import EEKStatus from '../../Pages/Kit/KitDashboard/EEK';
import EEKButtonGroup from '../../Pages/Kit/KitDashboard/EEKButtons';
import STIButton from '../../Pages/Kit/KitDashboard/STIButton';
import STITestingStatus from '../../Pages/Kit/KitDashboard/STITesting';
import ToxicologyButton from '../../Pages/Kit/KitDashboard/ToxicologyButton';
import ToxicologyScreening from '../../Pages/Kit/KitDashboard/ToxicologyScreening';

import SERVICE_LIST from './serviceList';

export const TESTING_KIT_LIST = [
	{
		heading: SERVICE_LIST.EARLY_EVIDENCE_KIT,
		details: <EEKStatus />,
		keys: ['eekOrderId', 'kitId'],
	},
	{
		heading: SERVICE_LIST.TOXICOLOGY_SCREENING,
		details: <ToxicologyScreening />,
		keys: ['toxicologyOrderId'],
	},
	{
		heading: SERVICE_LIST.STI_TESTING,
		details: <STITestingStatus />,
		keys: ['stiOrderId', 'stiKitId'],
	},
];

export const AVAILABLE_KIT_LIST = [
	{
		heading: SERVICE_LIST.EARLY_EVIDENCE_KIT,
		group: 'Immediate Care',
		details: <EvidenceKit />,
		button: <EEKButtonGroup />,
	},
	{
		heading: SERVICE_LIST.TOXICOLOGY_SCREENING,
		group: 'Immediate Care',
		details: <Toxicology />,
		button: <ToxicologyButton />,
	},
	{
		heading: SERVICE_LIST.STI_TESTING,
		group: 'Follow-Up Care',
		details: <STITesting />,
		button: <STIButton />,
	},
];

export const testingColor = {
	'01': 'lightBlue',
	'02': 'lightYellow',
};
