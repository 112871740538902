import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';

import FlowerIcon from '../../../../assets/listIcon/flowerIcon.svg';
import LightBlubIcon from '../../../../assets/listIcon/LightBlubIcon.svg';
import useStyles from '../../Styles/SharedStyles.Style';

function Bloom() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={FlowerIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Learn and heal from trauma in a private, supportive space"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={LightBlubIcon} />
					</ListItemIcon>
					<ListItemText
						primary={
							<>
								Six to eight sessions, each with:
								<ul type="circle">
									<li>A 30 minute video with captions and transcript</li>
									<li>A reflective activity to do in your own time</li>
									<li>Bonus content, reading lists and more</li>
								</ul>
							</>
						}
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
			</List>
		</Box>
	);
}

export default Bloom;
