/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Loading from '../../Components/Loading';
import NavigationBar from '../../Components/NavigationBar';
import SimpleDialog from '../../Components/SimpleDialog';
import { useStore } from '../../store/index';
import ROUTES from '../../utils/constants/routes';
import { serviceSelector } from '../../utils/selectors';

import SimpleAccordion from './Accordion';
import LearnMoreCard from './LearnMoreCard';
import ServicesFilter from './ServicesFilter';
import StartCard from './StartCard';
import useStyles from './Styles/Home.Style';
import ServiceTabPanel from './TabPanel';

// Amplify.configure(awsmobile);

function Home() {
	const [store] = useStore();
	const history = useHistory();
	const { user, isMobile, showLearnMoreCard, userServiceList, userWelcomeContent } = store;
	const classes = useStyles();
	const [enabledServiceList, filterList] = serviceSelector(userServiceList);
	const [selectedService, setSelectedService] = useState(filterList[0]);
	const [openIntroVideo, setOpenIntroVideo] = useState(false);

	const list = Object.keys(enabledServiceList).includes(selectedService)
		? { [selectedService]: enabledServiceList[selectedService] }
		: enabledServiceList;

	useEffect(() => {
		if (user && !user.isSchoolEmailVerified) {
			history.push(ROUTES.ENTER_EMAIL);
		}
	}, [user]);

	return user !== null && user !== '' ? (
		<Box className={classes.root}>
			<Header showNavBar showProfileMenu />
			<Box className={classes.body}>
				<StartCard setOpenIntroVideo={setOpenIntroVideo} userWelcomeContent={userWelcomeContent} />
				{showLearnMoreCard && isMobile && <LearnMoreCard />}
				{isMobile ? (
					<>
						<ServicesFilter
							filterList={filterList}
							selectedService={selectedService}
							setSelectedService={setSelectedService}
						/>
						{Object.keys(list).map(
							(item) =>
								list[item].length > 0 && (
									<Box className={classes.box} key={item}>
										<Typography className={classes.serviceHeading}>{item}</Typography>
										{list[item].map((obj) => (
											<SimpleAccordion data={obj} key={obj.heading} service={item} />
										))}
									</Box>
								)
						)}
					</>
				) : (
					<ServiceTabPanel />
				)}
			</Box>
			{isMobile && <NavigationBar />}
			<SimpleDialog
				button={false}
				dialogContentText=""
				dialogTitle=""
				introVideo={userWelcomeContent?.introVideo ? userWelcomeContent?.introVideo : null}
				introBanner={
					userWelcomeContent?.blogLink && userWelcomeContent?.introVideo
						? null
						: !isMobile
						? userWelcomeContent?.blogLink
						: userWelcomeContent?.blogLinkMobile
						? !isMobile
							? userWelcomeContent?.blogLink
							: userWelcomeContent?.blogLinkMobile
						: null
				}
				open={openIntroVideo}
				setOpen={setOpenIntroVideo}
			/>
			<Footer disclaimer />
		</Box>
	) : (
		<Box className={classes.loaderBox}>
			<Loading />
		</Box>
	);
}

export default Home;
