/* eslint-disable no-nested-ternary */
import React from 'react';

import { STI_TEST } from '../../../../utils/constants/STIKit/stiList';
import STIList from '../../../STIKit/STIList';

function STITesting() {
	return <STIList isLinked={false} list={STI_TEST} />;
}

export default STITesting;
