import { Grid, Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import ServiceCard from '../../Components/ServiceCard';
import TabBar from '../../Components/TabBar';
import TabPanel from '../../Components/TabPanel';
import { useStore } from '../../store';
import { serviceSelector } from '../../utils/selectors';

import LearnMoreCard from './LearnMoreCard';
import useStyles from './Styles/TabPanel.Style';

function ServiceTabPanel() {
	const classes = useStyles();
	const [value, setValue] = useState(0);
	const [store] = useStore();
	const { showLearnMoreCard, userServiceList } = store;
	const [enabledServiceList, filterList] = serviceSelector(userServiceList);
	const availableServiceList = Object.keys(enabledServiceList).filter(
		(item) => enabledServiceList[item].length > 0 && enabledServiceList[item]
	);

	return (
		<div className={classes.root}>
			<TabBar filterList={filterList} setValue={setValue} value={value} />
			<Box className={classes.tabBox}>
				<TabPanel className={classes.tabPanelBox} index={0} value={value}>
					{Object.keys(enabledServiceList).map(
						(item) =>
							enabledServiceList[item].length > 0 && (
								<Box key={item} paddingBottom={4}>
									<Typography className={classes.title}>{item}</Typography>
									{showLearnMoreCard && item === 'Immediate Care' && <LearnMoreCard />}
									<Grid container spacing={3}>
										{enabledServiceList[item].map((data) => (
											<Grid item key={data.heading}>
												<ServiceCard item={data} service={item} />
											</Grid>
										))}
									</Grid>
								</Box>
							)
					)}
				</TabPanel>
				{availableServiceList.map(
					(item, idx) =>
						enabledServiceList[item].length > 0 && (
							<TabPanel className={classes.tabPanelBox} index={idx + 1} key={item} value={value}>
								<Typography className={classes.title}>{item}</Typography>
								{showLearnMoreCard && item === 'Immediate Care' && <LearnMoreCard />}
								<Box>
									<Grid container spacing={3}>
										{enabledServiceList[item].map((data) => (
											<Grid item key={data.heading}>
												<ServiceCard item={data} service={item} />
											</Grid>
										))}
									</Grid>
								</Box>
							</TabPanel>
						)
				)}
			</Box>
		</div>
	);
}

export default ServiceTabPanel;
