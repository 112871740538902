import { Box, Button, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import bloomLogo from '../assets/partnership/bloomLogo.svg';
import callistoLogo from '../assets/partnership/callistoLogo.svg';
import DisableElements from '../Pages/Styles/DisableElements.Style';
import useStyles from '../Pages/Styles/ServiceCard.Style';
import { useStore } from '../store';
import { DEMO } from '../utils/constants/demoData';
import SERVICE_LIST from '../utils/constants/serviceList';
import { serviceColors } from '../utils/constants/servicesList';
import { STI_TESTING_STATUS } from '../utils/constants/stiTestingStatus';
import { testingColor } from '../utils/constants/testingKitList';
import { getOnClick, isServiceDeactivated } from '../utils/disableServices';

function ServiceCard({ item, index, service, showButtonsAtBottom, alignItemsNormal }) {
	const classes = { ...useStyles(), ...DisableElements() };
	const [store, dispatch] = useStore();
	const { stiTestingStatus, userServiceList, serviceDisabled, userWelcomeContent } = store;
	const STI_STATUS = STI_TESTING_STATUS[stiTestingStatus].label;

	const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];
	const serviceDisableInDemo =
		process.env.REACT_APP_ENV === DEMO &&
		item.heading !== SERVICE_LIST.EARLY_EVIDENCE_KIT &&
		item.heading !== SERVICE_LIST.PLAN_B &&
		service !== SERVICE_LIST.LIVE_SUPPORT_24_7;

	const serviceDeactivated = isServiceDeactivated(item?.serviceDetails, serviceDisabled);
	return (
		<Card
			className={clsx(classes.root, {
				[classes.rootWithButtons]: showButtonsAtBottom,
				[classes.disableElement]: serviceDeactivated,
			})}
			elevation={0}>
			<CardHeader
				action={
					typeof item.button === 'string' && (
						<Box className={clsx(classes.linkIconWrapper)}>
							<OpenInNewIcon />
						</Box>
					)
				}
				style={{ backgroundColor: item?.isCustom && `#${item?.headerColor}` }}
				className={clsx(classes.heading, classes[serviceColors[service]], {
					[classes.disableElement]: serviceDeactivated,
					[classes.careTeamHeading]: item.heading === SERVICE_LIST.CARE_TEAM,
					[classes.bloomHeading]: item.heading === SERVICE_LIST.BLOOM,
					[classes.callistoHeading]: item.heading === SERVICE_LIST.CALLISTO,
				})}
				disableTypography
				subheader={
					item.heading === SERVICE_LIST.STI_TESTING &&
					serviceData.dependsOn.includes(SERVICE_LIST.EEK) && (
						<span className={classes.status}>
							<img
								alt="An icon to signify the current status of STI/STD Testing."
								src={STI_TESTING_STATUS[stiTestingStatus].icon}
							/>
							&nbsp;{STI_STATUS}
						</span>
					)
				}
				title={
					item.heading === SERVICE_LIST.BLOOM ? (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							Healing with&nbsp;
							<img alt="bloom logo" src={bloomLogo} />
						</div>
					) : item.heading === SERVICE_LIST.CALLISTO ? (
						<div style={{ display: 'flex', alignItems: 'flexStart' }}>
							<img alt="callisto logo" src={callistoLogo} />
						</div>
					) : (
						<span style={{ color: item?.isCustom && `#${item?.headerFontColor}` }}>
							{item.heading}
						</span>
					)
				}
			/>
			{item?.serialNo && (
				<Box className={`${classes.serialNumBox} ${classes[testingColor[item?.serialNo]]}`}>
					{index}
				</Box>
			)}
			<Divider />
			<CardContent
				className={clsx(classes.cardContent, {
					[classes.alignNormal]: alignItemsNormal,
				})}>
				{item.details}
				{item.button && typeof item.button === 'string' ? (
					<Button
						className={clsx(classes.serviceButton, {
							[classes.bloomButton]: item.heading === SERVICE_LIST.BLOOM,
							[classes.callistoButton]: item.heading === SERVICE_LIST.CALLISTO,
						})}
						onClick={getOnClick(
							() => window.open(item.action, '_blank'),
							serviceDisabled,
							dispatch,
							item?.serviceDetails,
							serviceDisableInDemo
						)}>
						{item.button}
						{serviceDisableInDemo && (
							<span className={classes.status}>
								<img
									style={{ verticalAlign: 'middle' }}
									alt="An icon to signify the current status of STI/STD Testing."
									src={STI_TESTING_STATUS[stiTestingStatus].icon}
								/>
							</span>
						)}
					</Button>
				) : (
					item.button
				)}
			</CardContent>
		</Card>
	);
}

ServiceCard.propTypes = {
	item: PropTypes.shape({
		heading: PropTypes.string.isRequired,
		details: PropTypes.node.isRequired,
		button: PropTypes.node,
		action: PropTypes.string,
		serialNo: PropTypes.string,
		// eslint-disable-next-line react/forbid-prop-types
		serviceDetails: PropTypes.any,
		isCustom: PropTypes.bool,
		headerColor: PropTypes.string,
		headerFontColor: PropTypes.string,
	}),
	service: PropTypes.string.isRequired,
	index: PropTypes.number,
	showButtonsAtBottom: PropTypes.bool,
	alignItemsNormal: PropTypes.bool,
};

ServiceCard.defaultProps = {
	item: PropTypes.shape({
		button: null,
		action: '',
		serialNo: '',
	}),
	index: 1,
	showButtonsAtBottom: false,
	alignItemsNormal: false,
};
export default ServiceCard;
