import { Typography, Button, Menu, MenuItem, Grid } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AboutIcon from '@material-ui/icons/FavoriteBorder';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import userSignOut from '../Services/userSignOut';
import { useStore } from '../store/index';
import { DEMO } from '../utils/constants/demoData';
import ROUTES from '../utils/constants/routes';
import { getOnClick } from '../utils/disableServices';

import NavigationBar from './NavigationBar';
import useStyles from './Styles/Header.Style';

function Header({ showProfileMenu, showNavBar }) {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [store, dispatch] = useStore();
	const { userProfileName, isMobile, serviceDisabled, userWelcomeContent } = store;

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSignOut = (e) => {
		e.preventDefault();
		userSignOut(history, dispatch);
	};

	const handleResetPassword = async () => {
		history.push(ROUTES.RESET_PASSWORD);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleRoute = () => {
		history.push(ROUTES.HOME);
	};

	return (
		<Grid alignItems="center" className={classes.root} container justifyContent="space-between">
			<Grid item md={3}>
				<Button className={classes.logo} onClick={() => handleRoute()}>
					<div style={{ textAlign: 'left' }}>
						{userWelcomeContent?.logo ? (
							<>
								<img
									alt="deltaZeta partnership"
									src={userWelcomeContent?.logo}
									style={{ minInlineSize: '-webkit-fill-available' }}
								/>
								<div className={classes.partnershipTypography}>
									<Typography variant="subtitle2">
										In Partnership with <b>Leda</b>&nbsp;Health
									</Typography>
								</div>
							</>
						) : (
							<Typography>
								<b>Leda</b>&nbsp;Health
							</Typography>
						)}
					</div>
				</Button>
			</Grid>
			{showNavBar && !isMobile && (
				<Grid item xs={5}>
					<NavigationBar />
				</Grid>
			)}
			{showProfileMenu && (
				<Grid item md={3} style={{ textAlign: 'end' }}>
					<Button className={classes.userButton} onClick={handleMenuClick}>
						{userWelcomeContent?.userProfileLogo ? (
							<img
								alt="deltaZeta avatar"
								src={userWelcomeContent?.userProfileLogo}
								className={classes.userIcon}
							/>
						) : (
							<AccountCircleIcon className={classes.userIcon} />
						)}

						<Typography>
							{userProfileName && userProfileName !== '' && !isMobile ? userProfileName : ''}
						</Typography>
						{anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</Button>
					<Menu
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						elevation={0}
						getContentAnchorEl={null}
						keepMounted
						onClose={handleMenuClose}
						open={Boolean(anchorEl)}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}>
						{process.env.REACT_APP_ENV !== DEMO && (
							<MenuItem
								className={classes.menuItem}
								onClick={getOnClick(
									() =>
										window.open(
											'https://support.leda.co/hc/en-us/categories/4404959248155-User-Portal',
											'_blank'
										),
									serviceDisabled,
									dispatch
								)}>
								<HelpOutlineOutlinedIcon className={classes.icon} />
								<Typography variant="caption">FAQ</Typography>
							</MenuItem>
						)}
						<MenuItem classes={{ root: classes.logoutBtn }} onClick={(e) => handleResetPassword(e)}>
							<VpnKeyOutlinedIcon classes={{ root: classes.icon }} variant="caption" />
							<Typography variant="caption">Reset Password</Typography>
						</MenuItem>
						<MenuItem
							className={classes.menuItem}
							component="button"
							href="https://www.leda.co/about"
							rel="noopener noreferrer"
							target="_blank">
							<AboutIcon className={classes.icon} />
							<Typography variant="caption">About Leda</Typography>
						</MenuItem>
						<MenuItem className={classes.menuItem} onClick={(e) => handleSignOut(e)}>
							<ExitToAppIcon className={classes.icon} />
							<Typography variant="caption">Logout</Typography>
						</MenuItem>
					</Menu>
				</Grid>
			)}
		</Grid>
	);
}

Header.propTypes = {
	showProfileMenu: PropTypes.bool,
	showNavBar: PropTypes.bool,
};

Header.defaultProps = {
	showProfileMenu: false,
	showNavBar: false,
};

export default Header;
