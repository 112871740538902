import { Box, Link, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';

import CalendarIcon from '../../../../assets/listIcon/CalendarIcon.svg';
import LabCoatIcon from '../../../../assets/listIcon/LabCoat.svg';
import LightBlubIcon from '../../../../assets/listIcon/LightBlubIcon.svg';
import MoneyIcon from '../../../../assets/listIcon/Money.svg';
import useStyles from '../../Styles/SharedStyles.Style';

function PrEP() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={LightBlubIcon} />
					</ListItemIcon>
					<ListItemText
						primary={
							<span>
								Medicine that reduces your chances of getting HIV from sex or injection drug use
								-&nbsp;
								<Link
									href="https://www.hiv.gov/hiv-basics/hiv-prevention/using-hiv-medication-to-reduce-risk/pre-exposure-prophylaxis"
									target="_blank"
									className={classes.listTextLink}>
									<u>More info</u>
								</Link>
							</span>
						}
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={LightBlubIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Recommended that treatment begin before known exposure to HIV"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={LabCoatIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Telehealth clinician visits offered for immediate help and long-term clinical care"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={CalendarIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Tests are shipped every 3 months automatically for continued provision of medication and treatment"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={MoneyIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Prescription cost not covered by Leda"
						primaryTypographyProps={{ className: classes.italicsListText }}
					/>
				</ListItem>
				{/* <ListItem
          className={classes.listItem}
          button
          component={Link}
          href="https://www.hiv.gov/hiv-basics/hiv-prevention/using-hiv-medication-to-reduce-risk/pre-exposure-prophylaxis"
          target="_blank">
          <ListItemIcon className={classes.listIcon}>
            <img alt="An icon to support information." src={LinkIcon} />
          </ListItemIcon>
          <ListItemText
            primary="For more information about PrEP click here"
            primaryTypographyProps={{ className: classes.listTextLink }}
          />
        </ListItem> */}
			</List>
		</Box>
	);
}

export default PrEP;
