const ROUTES = {
	// misc
	DEFAULT: '/',
	HOME: '/home',
	LEARN_MORE: '/learn-more',
	SIGN_IN: '/sign-in',
	ELIGIBILITY_QUIZ: '/eligibility-quiz',
	WITNESS_FORM: '/witness',
	ERROR: '/404',
	EDUCATION: '/education',

	// early evidence kit
	ORDER_KIT: '/order-kit',
	ACTIVATE_KIT: '/activate-kit',
	PICK_UP_KIT: '/pick-up-kit',
	TRACK_KIT_ORDER: '/track-kit-order',
	CANCEL_ORDER: '/cancel-order',
	NEXT_STEPS: '/next-steps',
	KIT_DASHBOARD: '/my-tests',
	EEK_TEST_RESULT: '/eek-lab-results/:kitId',

	// plan b
	ORDER_PLAN_B: '/order-planb',
	PICK_UP_PLAN_B: '/pickup-planb',
	TRACK_PLAN_B_ORDER: '/track-planb-order',
	CANCEL_PLAN_B_ORDER: '/cancel-planb-order',

	// sti testing
	STI_REVIEW: '/sti-review',
	ORDER_TEST: '/order-test',
	PICK_UP_STI: '/pickup-sti',
	TRACK_STI_ORDER: '/track-sti-order',
	VIEW_TEST_RESULTS: '/view-test-results',

	// Toxicology Screening
	TOXICOLOGY_REVIEW: '/toxicology-review',
	ORDER_TOXICOLOGY: '/order-toxicology',
	PICK_UP_TOXICOLOGY: '/pickup-toxicology',
	TRACK_TOXICOLOGY_ORDER: '/track-toxicology-order',

	// sign up
	USER_CONFIRM: '/user/confirm',
	SETUP_ACCOUNT: '/setup-account',
	ENTER_EMAIL: '/enter-email',
	ENTER_VERIFICATION_CODE: '/enter-verification-code',
	RESET_PASSWORD: '/reset-password',
	SET_NEW_PASSWORD: '/set-new-password',
	FORGOT_PASSWORD: '/forgot-password',

	RESOURCE_CENTER: '/resource-center',

	// content pages
	ARTICLE_1: "/what-to-say-when-someone-tells-you-they've-been-sexually-assaulted",
	ARTICLE_2: '/how-to-set-boundaries-for-a-date',
	ARTICLE_3: '/hookup-culture',
	ARTICLE_4: '/sti-awareness-and-prevention',
	ARTICLE_5: '/what-to-do-if-you-experience-sexual-assault',
};

export default ROUTES;
