import { Box, List, ListItem, ListItemIcon, ListItemText, Link } from '@material-ui/core';
import React from 'react';

import AlarmClockIcon from '../../../../assets/listIcon/AlarmClockIcon.svg';
import BoxIcon from '../../../../assets/listIcon/BoxIcon.svg';
import HeartIcon from '../../../../assets/listIcon/HeartIcon.svg';
import LabCoatIcon from '../../../../assets/listIcon/LabCoat.svg';
import MoneyIcon from '../../../../assets/listIcon/Money.svg';
import useStyles from '../../Styles/SharedStyles.Style';

function PEP() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={HeartIcon} />
					</ListItemIcon>
					<ListItemText
						primary={
							<span>
								Medicine to prevent HIV after a possible exposure -
								<Link
									target="_blank"
									className={classes.listTextLink}
									href="https://www.hiv.gov/hiv-basics/hiv-prevention/using-hiv-medication-to-reduce-risk/post-exposure-prophylaxis">
									<u>More info</u>
								</Link>
							</span>
						}
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={AlarmClockIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Testing should be done within 72 hours if possible after known exposure"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={LabCoatIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Telehealth clinician visits offered for immediate help and long-term clinical care"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={BoxIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Tests are shipped every 3 months automatically for continued provision of medication and treatment"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={MoneyIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Prescription cost not covered by Leda"
						primaryTypographyProps={{ className: classes.italicsListText }}
					/>
				</ListItem>
				{/* <ListItem
          className={classes.listItem}
          button
          component={Link}
          href="https://www.hiv.gov/hiv-basics/hiv-prevention/using-hiv-medication-to-reduce-risk/post-exposure-prophylaxis"
          target="_blank">
          <ListItemIcon className={classes.listIcon}>
            <img alt="An icon to support information." src={LinkIcon} />
          </ListItemIcon>
          <ListItemText
            primary="For more information about PEP click here"
            primaryTypographyProps={{ className: classes.listTextLink }}
          />
        </ListItem> */}
			</List>
		</Box>
	);
}

export default PEP;
