import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';

import AlarmClockIcon from '../../../../assets/listIcon/AlarmClockIcon.svg';
import HospitalIcon from '../../../../assets/listIcon/HospitalIcon.svg';
import PillIcon from '../../../../assets/listIcon/PillIcon.svg';
import RingIcon from '../../../../assets/listIcon/RingIcon.svg';
import useStyles from '../../Styles/SharedStyles.Style';

function PlanB() {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<List>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={HospitalIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Emergency contraception that helps prevent pregnancy before it starts"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={AlarmClockIcon} />
					</ListItemIcon>
					<ListItemText
						primary="Must be taken within 72 hours of incident to be effective"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={RingIcon} />
					</ListItemIcon>
					<ListItemText
						primary="May not be as effective for body mass indexes over 26 or 155lbs"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>
						<img alt="An icon to support information." src={PillIcon} />
					</ListItemIcon>
					<ListItemText
						primary="NOT an abortion pill"
						primaryTypographyProps={{ className: classes.listText }}
					/>
				</ListItem>
			</List>
		</Box>
	);
}

export default PlanB;
