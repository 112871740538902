import { Box, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import PrivacyPolicy from '../Pages/PrivacyPolicy';
import UserAgreement from '../Pages/UserAgreement';
import { useStore } from '../store/index';

import PhoneLink from './PhoneLink';
import styles from './Styles/Footer.Style';

function Footer(props) {
	const classes = styles();
	const [store] = useStore();
	const { isMobile, userWelcomeContent } = store;
	const { disclaimer, customClass } = props;

	const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
	const [openUserAgreement, setOpenUserAgreement] = useState(false);

	return disclaimer ? (
		<Box className={classes.footer}>
			<Box
				className={classes.alertBox}
				style={{ backgroundColor: `#${userWelcomeContent?.primaryColor}` }}>
				<Typography className={classes.alertText}>
					{
						"Please be aware that the portal is not currently HIPAA Compliant. Leda Health values your privacy and will never disclose your confidential information. The HIPAA Privacy Rule is a requirement for health plans and other covered healthcare providers to protect and explain individual rights with respect to their personal health information (PHI). Given this organization is currently not utilizing Leda's internal clinical care team and have requested that PHI is not shared with Leda Health, we are not following HIPAA Privacy Rules. "
					}
				</Typography>
			</Box>
			<Box
				className={classes.alertBox}
				style={{ backgroundColor: `#${userWelcomeContent?.primaryColor}` }}>
				<Typography className={classes.alertText}>
					{'If you are in immediate danger, please dial '}
					<PhoneLink contactNumber="911" text="911" />
				</Typography>
			</Box>
			<Box
				className={classes.disclaimerBox}
				style={{ backgroundColor: `#${userWelcomeContent?.secondaryColor}` }}>
				<Box className={classes.supportBox}>
					<Typography className={classes.supportText}>
						{
							'You can get in touch with Leda for technical support or any questions you may have at '
						}
						<a
							className={classes.link}
							href="https://support.leda.co/"
							rel="noreferrer"
							target="_blank">
							support@leda.co
						</a>
					</Typography>
				</Box>
				<Typography className={classes.disclaimerHeading}>Disclaimer</Typography>
				<Typography className={classes.disclaimerText}>
					{'Leda Health offers support, resources, and a means to gather information after a sexual assault.' +
						' Leda Health is not a medical service and the information we provide is not a substitute for professional medical advice, diagnosis, treatment, or counseling. If you are injured or require immediate medical attention, please call 911 or visit a hospital. Our User Portal may help you locate a sexual assault nurse examiner. Leda Health does not offer legal advice to its users. If you have questions about your legal rights or options after a sexual assault, contact local law enforcement and/or an attorney promptly. If you fail to do so, you may lose important legal rights over time.We cannot guarantee that any information collected through our Early-Evidence kit will be offered, admitted, or relied upon in a court of law.The ultimate admissibility and reliability of information that you collect through Leda Health’s services will be dependent on the specific circumstances of your case and applicable law. To obtain the full benefits of our service, you must carefully read and follow all related instructions. Do not tamper with or improperly alter or use any of the materials included with our services. If you have any questions or concerns, please contact us at: support@leda.co. To learn more please click '}
					<a
						href="https://www.leda.co/agreements"
						rel="noreferrer"
						style={{ textDecoration: 'none', color: '#F48273' }}
						target="_blank">
						here
					</a>
					.
				</Typography>
			</Box>
		</Box>
	) : (
		<div className={customClass || classes.footerDiv}>
			<Typography className={classes.footerText}>
				ⓒ 2019-{new Date().getFullYear()} Leda Health Corporation. All rights reserved.
			</Typography>
			<div className={classes.footerLinks}>
				{isMobile ? '  ' : <FiberManualRecordIcon classes={{ root: classes.icon }} />}
				<Typography className={classes.footerTextLink} onClick={() => setOpenUserAgreement(true)}>
					User Agreement
				</Typography>
				{isMobile ? '  ' : <FiberManualRecordIcon classes={{ root: classes.icon }} />}
				<Typography className={classes.footerTextLink} onClick={() => setOpenPrivacyPolicy(true)}>
					Privacy Policy
				</Typography>
				{isMobile ? '  ' : <FiberManualRecordIcon classes={{ root: classes.icon }} />}
				<Typography className={classes.footerText}>
					<a
						className={classes.link}
						href="mailto:support@leda.co"
						rel="noreferrer"
						target="_blank">
						support@leda.co
					</a>
				</Typography>
			</div>
			<PrivacyPolicy
				openPrivacyPolicy={openPrivacyPolicy}
				setOpenPrivacyPolicy={setOpenPrivacyPolicy}
			/>
			<UserAgreement
				openUserAgreement={openUserAgreement}
				setOpenUserAgreement={setOpenUserAgreement}
			/>
		</div>
	);
}

Footer.propTypes = {
	disclaimer: PropTypes.bool,
	customClass: PropTypes.string,
};

Footer.defaultProps = {
	disclaimer: false,
	customClass: '',
};
export default Footer;
