import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	appBox: {
		background: '#FFFFFF',
	},
	tab: {
		textTransform: 'none',
		fontSize: theme.spacing(2),
		fontWeight: 500,
		width: 'fit-content',
		minWidth: 'unset',
	},
	title: {
		fontSize: 20,
		color: '#003366',
		paddingBottom: theme.spacing(3),
	},
	bar: {
		maxWidth: theme.spacing(170.5),
		margin: '0 auto',
		padding: theme.spacing(0, 4),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 11.25),
			maxWidth: 'unset',
		},
	},
	tabBox: {
		backgroundColor: '#FAFAFA',
	},
	tabIndicator: {
		height: theme.spacing(0.5),
		borderRadius: 27,
	},
	tabPanelBox: {
		maxWidth: theme.spacing(170.5),
		margin: '0 auto',
		padding: theme.spacing(4, 4),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(4, 11.25),
			maxWidth: 'unset',
		},
	},
}));

export default useStyles;
