import { BottomNavigation, BottomNavigationAction, Box } from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Home from '../assets/bottomNavIcon/Home.svg';
import Resources from '../assets/bottomNavIcon/Resources.svg';
import { useStore } from '../store';
import ROUTES from '../utils/constants/routes';

import useStyles from './Styles/NavigationBar.Style';

const NAVIGATION_OPTIONS = [
	{ title: 'Home', path: ROUTES.HOME, icon: Home },
	// { title: 'My Tests', path: ROUTES.KIT_DASHBOARD, icon: MyTest },
	{ title: 'Resources', path: ROUTES.RESOURCE_CENTER, icon: Resources },
	{ title: 'Education', path: ROUTES.EDUCATION, iconComponent: BookIcon },
];

function NavigationBar() {
	const classes = useStyles();
	const [store] = useStore();
	const { isMobile } = store;
	const history = useHistory();
	const location = useLocation();
	const currentRoute = NAVIGATION_OPTIONS.filter((item) => location.pathname === item.path);
	const [selectedItem, setSelectedItem] = useState(currentRoute[0]?.title);

	const updateSelectedItem = (item) => {
		setSelectedItem(item.title);
		history.push(item.path);
	};

	return isMobile ? (
		<BottomNavigation className={classes.navBar} showLabels value={selectedItem}>
			{NAVIGATION_OPTIONS.map((item) => (
				<BottomNavigationAction
					className={classes.icon}
					color="primary"
					icon={
						item.iconComponent ? <item.iconComponent /> : <img alt={item.title} src={item.icon} />
					}
					key={item.title}
					label={item.title}
					onClick={() => updateSelectedItem(item)}
					value={item.title}
				/>
			))}
		</BottomNavigation>
	) : (
		<Box className={classes.root}>
			{NAVIGATION_OPTIONS.map((item) => (
				<Box
					className={selectedItem === item.title ? classes.selectedItem : classes.item}
					key={item.title}
					onClick={() => updateSelectedItem(item)}>
					{item.title}
				</Box>
			))}
		</Box>
	);
}

export default NavigationBar;
