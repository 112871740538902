import { Button, Divider, Grid, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MinimizeIcon from '@material-ui/icons/Minimize';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Clock from '../../assets/Clock.svg';
import { useStore } from '../../store';
import ROUTES from '../../utils/constants/routes';
import { getOnClick } from '../../utils/disableServices';

import useStyles from './Styles/LearnMoreCard.Style';

function LearnMoreCard() {
	const classes = useStyles();
	const [store, dispatch] = useStore();
	const [expanded, setExpanded] = useState('expanded');
	const { isMobile, serviceDisabled } = store;
	const history = useHistory();

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleRoute = () => {
		history.push(ROUTES.LEARN_MORE);
	};

	return (
		<Accordion
			elevation={0}
			className={classes.main}
			expanded={expanded === 'expanded'}
			onChange={handleChange('expanded')}>
			<AccordionSummary
				className={clsx(classes.accordionSummary, {
					[classes.expandIcon]: expanded !== 'expanded',
					[classes.expandIconMobile]: expanded !== 'expanded' && isMobile,
				})}
				expandIcon={
					expanded === 'expanded' ? (
						<div>
							<MinimizeIcon />
						</div>
					) : (
						<div>
							<AddIcon />
						</div>
					)
				}>
				<div>
					<Typography className={classes.heading}>After Sexual Assault</Typography>
					{expanded !== 'expanded' && (
						<div className={classes.learnMoreButton}>
							<Divider />
							<Button
								className={classes.button}
								endIcon={<ArrowForwardIcon className={classes.arrowIcon} />}
								onClick={getOnClick(handleRoute, serviceDisabled, dispatch)}>
								Learn more
							</Button>
						</div>
					)}
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{isMobile ? (
					<Grid container direction="column">
						<Grid className={classes.contentBox} item>
							<Typography className={classes.content}>
								{'We recommend you seek care ASAP as many services are time-sensitive.' +
									' Before collecting DNA, it is best to avoid certain things, like eating or drinking.' +
									' Click Learn more below for a full list of recommendations to prepare you for care.'}
							</Typography>
							<img
								alt="An icon for the Learn More card to show time information."
								className={classes.clock}
								src={Clock}
							/>
						</Grid>
						<Grid className={classes.learnMoreButton} item>
							<Button
								className={classes.button}
								fullWidth
								onClick={getOnClick(handleRoute, serviceDisabled, dispatch)}>
								Learn more
								<ArrowForwardIcon className={classes.arrowIcon} />
							</Button>
						</Grid>
					</Grid>
				) : (
					<Grid container direction="column" alignItems="center">
						<Grid
							className={classes.contentBox}
							container
							item
							justifyContent="space-between"
							xs={12}>
							<Grid item xs={10}>
								<Typography className={classes.content}>
									{'We recommend you seek care ASAP as many services are time-sensitive.' +
										' Before collecting DNA, it is best to avoid certain things, like eating or drinking.' +
										' Click Learn more below for a full list of recommendations to prepare you for care.'}
								</Typography>
							</Grid>
							<Grid className={classes.clockGrid} item xs={2} alignItems="center">
								<img alt="An icon to signify the time information." src={Clock} />
								<br />
							</Grid>
							{expanded === 'expanded' && (
								<Grid className={classes.learnMoreButton} item xs={12} md={8} lg={8}>
									<Divider />
									<Button
										className={classes.button}
										endIcon={<ArrowForwardIcon className={classes.arrowIcon} />}
										onClick={getOnClick(handleRoute, serviceDisabled, dispatch)}>
										Learn more
									</Button>
								</Grid>
							)}
						</Grid>
					</Grid>
				)}
			</AccordionDetails>
		</Accordion>
	);
}

export default LearnMoreCard;
