import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Button,
	Box,
	AccordionActions,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import lockIcon from '../../assets/Lock.svg';
import bloomLogo from '../../assets/partnership/bloomLogo.svg';
import { useStore } from '../../store';
import { DEMO } from '../../utils/constants/demoData';
import SERVICE_LIST from '../../utils/constants/serviceList';
import { serviceColors } from '../../utils/constants/servicesList';
import { STI_TESTING_STATUS } from '../../utils/constants/stiTestingStatus';
import { getOnClick, isServiceDeactivated } from '../../utils/disableServices';
import disableElements from '../Styles/DisableElements.Style';

import useStyles from './Styles/Accordion.Style';

function SimpleAccordion({ data, service }) {
	const classes = { ...disableElements(), ...useStyles() };
	const [store, dispatch] = useStore();
	const { stiTestingStatus, userServiceList, serviceDisabled } = store;

	const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];

	const serviceDeactivated = isServiceDeactivated(data?.serviceDetails, serviceDisabled);

	const serviceDisableInDemo =
		process.env.REACT_APP_ENV === DEMO &&
		data.heading !== SERVICE_LIST.EARLY_EVIDENCE_KIT &&
		data.heading !== SERVICE_LIST.PLAN_B &&
		service !== SERVICE_LIST.LIVE_SUPPORT_24_7;

	return (
		<Accordion
			className={clsx(classes.root, classes[serviceColors[service]], {
				[classes.disableElement]: serviceDeactivated,
				[classes.bloomHeading]: data.heading === SERVICE_LIST.BLOOM,
			})}
			elevation={0}
			style={{
				backgroundColor:
					data.heading === SERVICE_LIST.CARE_TEAM
						? '#ffd7ce'
						: data?.isCustom && `#${data?.headerColor}`,
			}}>
			<AccordionSummary className={classes.head} expandIcon={<ExpandMoreIcon />}>
				<Box className={classes.title}>
					<Typography className={classes.heading}>
						{data.heading === SERVICE_LIST.BLOOM ? (
							<div style={{ display: 'flex', alignItems: 'flexStart' }}>
								Healing with&nbsp;
								<img alt="bloom logo" src={bloomLogo} />
							</div>
						) : (
							<span style={{ color: data?.isCustom && `#${data?.headerFontColor}` }}>
								{data.heading}
							</span>
						)}
					</Typography>
					{serviceDisableInDemo && (
						<span className={classes.status}>
							<img alt="An icon to signify the current status of STI/STD Testing." src={lockIcon} />
						</span>
					)}

					{data.heading === SERVICE_LIST.STI_TESTING &&
						serviceData.dependsOn.includes(SERVICE_LIST.EEK) && (
							<Typography variant="subtitle2">
								<img
									alt="An icon for different information points."
									src={STI_TESTING_STATUS[stiTestingStatus].icon}
								/>
								&nbsp;{STI_TESTING_STATUS[stiTestingStatus].label}
							</Typography>
						)}
				</Box>
			</AccordionSummary>
			<AccordionDetails className={classes.details}>{data.details}</AccordionDetails>
			{data.button && (
				<AccordionActions className={classes.action}>
					{typeof data.button === 'string' ? (
						<Button
							className={classes.serviceButton}
							onClick={getOnClick(
								() => window.open(data?.action, '_blank'),
								serviceDeactivated,
								dispatch,
								data?.serviceDetails,
								serviceDisableInDemo
							)}>
							{data.button}
							&nbsp;
							<OpenInNewIcon fontSize="small" />
						</Button>
					) : (
						data.button
					)}
				</AccordionActions>
			)}
		</Accordion>
	);
}

SimpleAccordion.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string.isRequired,
		details: PropTypes.node.isRequired,
		button: PropTypes.node,
		action: PropTypes.string,
		serviceDetails: PropTypes.shape(PropTypes.string),
		isCustom: PropTypes.bool,
		headerColor: PropTypes.string,
		headerFontColor: PropTypes.string,
	}),
	service: PropTypes.string.isRequired,
};

export default SimpleAccordion;
