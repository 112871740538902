import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			aria-labelledby={`full-width-tab-${index}`}
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			role="tabpanel"
			{...other}>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
	children: null,
};

export default TabPanel;
